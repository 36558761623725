import React, { Component } from "react";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import * as actions from "../../../store/actions/index";
import { Modal } from "../../UI";
import { Button, FormGroup, Form, Input } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./AGBOverlay.module.css";

class AGBOverlay extends Component {
  constructor(props) {
    super(props);
      this.state = {
        values: {
          checkbox: ""
        },
        validate: {
          checkboxState: ""
        }
      }  
  }


  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState(prevState => ({
      values: {                   
          ...prevState.values,    
          [name]: value,      
      }
    }));
  };

  hideOverlay = () => {
    this.props.onHideOverlay();
  };

  onDownloadTermsOfUse = (e) => {
    const { appLanguage, onDownloadFile } = this.props;

    e.preventDefault();

    onDownloadFile(
      appLanguage === "en"
        ? "assets/pdf/20220401_Terms_of_Use.pdf"
        : "assets/pdf/20220401_AGB_und_Nutzungsbedingungen.pdf",
      "pdf",
      appLanguage === "en"
        ? "20220401_Terms_of_Use"
        : "20220401_AGB_und_Nutzungsbedingungen"
    );
  };

  onDownloadDataPrivacy = (e) => {
    const { appLanguage, onDownloadFile } = this.props;

    e.preventDefault();

    onDownloadFile(
      appLanguage === "en"
        ? "assets/pdf/20220401_Data_protection_information_for_the_collection_of_personal_data.pdf"
        : "assets/pdf/20220401_Datenschutzhinweis_nach Art.13_DSG-VO.pdf",
      "pdf",
      appLanguage === "en"
        ? "20220401_Data_protection_information_for_the_collection_of_personal_data"
        : "20220401_Datenschutzhinweis_nach Art.13_DSG-VO"
    );
  };

  onSubmitRequest = (event, values) => {
    event.preventDefault();
    
    this.props.onAcceptNewAGB();
  };

  render() {
    const { appLanguage, isVisible } = this.props;
    const { checkbox } = this.state.values;

    return (
      <Modal
        size="lg"
        onHideModal={this.hideOverlay}
        isOpen={isVisible}
        modalBodyStyle={{ align: "center" }}
        modalHeader={<Translate id="AGBOverlay.Header" />}
        modalBody={
          <>
            <div>
              <br />
              {appLanguage === "en" ? (
                <span>
                  The Medienverand der freien Presse e.V. has taken over the
                  business of the Verband Deutscher Zeitschriftenverleger e.V.
                  The General Terms and Conditions (GTC) and the information
                  according to Art. 13 DSGVO about the processing of personal
                  data have changed as a result. We would therefore like to ask
                  you for your consent or acknowledgment:
                </span>
              ) : (
                <span>
                  Der MVFP Medienverband der freien Presse e.V. hat die
                  Geschäfte des VDZ Verband Deutscher Zeitschriftenverleger e.V.
                  übernommen. Die Allgemeinen Geschäftsbedingungen (AGB) und die
                  Information nach Art. 13 DSGVO über die Verarbeitung
                  personenbezogener Daten haben sich insoweit geändert. Wir
                  möchten Sie deshalb um Ihre Zustimmung bzw. Kenntnisnahme
                  bitten:
                </span>
              )}
            </div>
            <br />
            <br />
            <div>
              <Form onSubmit={(e) => this.onSubmitRequest(e)}>
              <FormGroup check>
                <Input 
                  type="checkbox" 
                  name="checkbox"
                  required={true}
                  className={classes.Checkbox}
                  value={checkbox}
                  onChange={(e) => this.handleChange(e)}
                />

                <div className={classes.CheckBoxText}>
                  <Translate id="AGBOverlay.AGB.IAccept.FirstPart" />{" "}
                  <span
                    onClick={e => this.onDownloadTermsOfUse(e)}
                    className={classes.TermsOfUse}
                  >
                    <Translate id="AGBOverlay.AGB.Term" />
                  </span>{" "}
                  <Translate id="AGBOverlay.AGB.IAccept.SecondPart" />{" "}
                </div>
              </FormGroup>

              <FormGroup check>
                <Input 
                  type="checkbox" 
                  name="checkbox2"
                  required={true}
                  className={classes.Checkbox}
                  value={checkbox}
                  onChange={(e) => this.handleChange(e)}
                />

                <div className={classes.CheckBoxText}>
                    <Translate id="AGBOverlay.DataPrivacy.IAccept.FirstPart" />{" "}
                    <span
                      onClick={e => this.onDownloadDataPrivacy(e)}
                      className={classes.TermsOfUse}
                    >
                      <Translate id="AGBOverlay.DataPrivacy.Term" />
                    </span>{" "}
                    <Translate id="AGBOverlay.DataPrivacy.IAccept.SecondPart" />{" "}
                  </div>
              </FormGroup>

              <br />
              <br />

              <div>
                  <FormGroup style={{ textAlign: "center" }}>
                    <Button className={classes.PrimaryButton}>
                      <Translate id="General.Save" />
                    </Button>
                  </FormGroup>
                </div>

              </Form>
            </div>
          </>
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLanguage: state.auth.appLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDownloadFile: (filePath, fileType, fileName) =>
      dispatch(actions.downloadFile(filePath, fileType, fileName)),
    onAcceptNewAGB: () => dispatch(actions.acceptNewAGB()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AGBOverlay);
