import React from "react";
import PropTypes from "prop-types";
import { emailPattern } from "../../../helpers/utility";
import { translateAsLiteral } from "../../../helpers/utility";
import Translate from "../../../hoc/Locale/Translate";
import {
  FormFeedback,
  FormGroup,
  Input,
  Label
} from 'reactstrap';

class EmailField extends React.Component {
  constructor(props) {
    super(props);
  }

  validateEmail(e) {
    const { validate } = this.props;
  
    if (emailPattern.test(e.target.value) && e.target.value.length > 0 && e.target.value.length <= 255) {
      validate.emailState = 'has-success';
    } else {
      validate.emailState = 'has-danger';
    }
  
    this.props.onUpdateValidate({ validate });
  }

  render() {
    const { label, disabled, appLanguage, value } = this.props;
    return (
      <FormGroup>
      <Label for="email">
        {label
            ? label
            : translateAsLiteral(appLanguage, "UserProfile.Email.Header")}
      </Label>
      <Input
        id="email"
        name="email"
        autoComplete="username"
        placeholder=""
        type="email"
        disabled={disabled}
        required={true}
        value={value}
        valid={ this.props.validate.emailState === 'has-success' }
        invalid={ this.props.validate.emailState === 'has-danger' }
        onChange={ (e) => {
                            this.validateEmail(e);
                            this.props.onChange(e);
                          } }
      />

    {(value && value.length > 0)  ?
      <FormFeedback>
          <Translate id="UserProfile.Email.Error" />
      </FormFeedback> :
      <FormFeedback>
        <Translate id="UserProfile.Email.Required" />
      </FormFeedback>

      }
    </FormGroup>
      /*     <AvField
        type="email"
        name="email"
        autoComplete="username"
        label={
          label
            ? label
            : translateAsLiteral(appLanguage, "UserProfile.Email.Header")
        }
        disabled={disabled}
        value={value}
        placeholder=""
        errorMessage={translateAsLiteral(appLanguage, "UserProfile.Email.Error")}
        validate={{
          required: {
            value: true,
            errorMessage: translateAsLiteral(
              appLanguage,
              "UserProfile.Email.Required"
            )
          },
          pattern: {
            value: emailPattern,
            errorMessage: translateAsLiteral(
              appLanguage,
              "UserProfile.Email.Error"
            )
          },
          maxLength: {
            value: 255,
            errorMessage: translateAsLiteral(
              appLanguage,
              "UserProfile.Email.MaxLength"
            )
          }
        }}
      /> */
    );
  }


};

EmailField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  appLanguage: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default EmailField;
