import React, { Component } from "react";
import FontAwesome from "react-fontawesome";
import { Tooltip as TooltipIcon } from "reactstrap";
import classes from "./Tooltip.module.css";

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  render() {
    const { id, text } = this.props;
    const { tooltipOpen } = this.state;

    return (
      <>
        <div className={classes.Main}>
          <FontAwesome
            name="info-circle"
            size="2x"
            id={id}
            className={classes.Icon}
          />
        </div>
        <TooltipIcon
          placement="top"
          isOpen={tooltipOpen}
          target={id}
          toggle={this.toggle}
        >
          {text}
        </TooltipIcon>
      </>
    );
  }
}

export default Tooltip;
