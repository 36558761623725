import React from "react";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./Pagination.module.css";

const pagination = (props) => {
  const { paginationInfo, dataCount, onPageChange } = props;

  let currentPage = paginationInfo ? paginationInfo.aktuelleSeite : 1;
  let previousPage = paginationInfo ? currentPage - 1 : 1;
  let nextPage = paginationInfo ? currentPage + 1 : 1;
  let limit = paginationInfo ? paginationInfo.seitenGroesse : 1;
  let totalPages = paginationInfo ? paginationInfo.seiten : 1;

  let previousItem = (
    <PaginationItem disabled>
      <PaginationLink>{"<"}</PaginationLink>
    </PaginationItem>
  );

  let nextItem = (
    <PaginationItem>
      <PaginationLink onClick={onPageChange.bind(this, nextPage)}>
        >
      </PaginationLink>
    </PaginationItem>
  );

  if (currentPage > 1) {
    previousItem = (
      <PaginationItem>
        <PaginationLink onClick={onPageChange.bind(this, previousPage)}>
          {"<"}
        </PaginationLink>
      </PaginationItem>
    );
  }

  if (currentPage === totalPages) {
    nextItem = (
      <PaginationItem>
        <PaginationLink disabled>></PaginationLink>
      </PaginationItem>
    );
  }

  return (
    totalPages > 1 && (
      <Pagination>
        <PaginationItem>
          <PaginationLink onClick={onPageChange.bind(this, 1)}>
            {"<<"}
          </PaginationLink>
        </PaginationItem>
        {previousItem}
        <PaginationItem>
          {currentPage > totalPages / 2 && totalPages > 2 && totalPages > 3 && (
            <PaginationLink onClick={onPageChange.bind(this, 1)}>
              {1}
            </PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem>
          {currentPage > totalPages / 2 && totalPages > 2 && totalPages > 3 && (
            <PaginationLink onClick={onPageChange.bind(this, 2)}>
              {2}
            </PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem>
          {currentPage > totalPages / 2 && totalPages > 2 && totalPages > 3 && (
            <PaginationLink>{"..."}</PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem>
          {currentPage !== 1 && (
            <PaginationLink onClick={onPageChange.bind(this, previousPage)}>
              {previousPage}
            </PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem active>
          <PaginationLink>{currentPage}</PaginationLink>
        </PaginationItem>
        <PaginationItem>
          {dataCount >= limit && (
            <PaginationLink onClick={onPageChange.bind(this, nextPage)}>
              {nextPage}
            </PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem>
          {currentPage < 2 && totalPages > 2 && (
            <PaginationLink onClick={onPageChange.bind(this, nextPage + 1)}>
              {nextPage + 1}
            </PaginationLink>
          )}
        </PaginationItem>
        <PaginationItem>
          {currentPage < totalPages - 1 &&
            currentPage < totalPages / 2 &&
            totalPages > 3 && <PaginationLink>{"..."}</PaginationLink>}
        </PaginationItem>
        <PaginationItem>
          {currentPage < totalPages - 2 &&
            currentPage < totalPages / 2 &&
            totalPages > 3 && (
              <PaginationLink onClick={onPageChange.bind(this, totalPages - 1)}>
                {totalPages - 1}
              </PaginationLink>
            )}
        </PaginationItem>
        <PaginationItem>
          {currentPage < totalPages - 1 &&
            currentPage < totalPages / 2 &&
            totalPages > 3 && (
              <PaginationLink onClick={onPageChange.bind(this, totalPages)}>
                {totalPages}
              </PaginationLink>
            )}
        </PaginationItem>
        {nextItem}
        <PaginationItem>
          <PaginationLink onClick={onPageChange.bind(this, totalPages)}>
            {">>"}
          </PaginationLink>
        </PaginationItem>
      </Pagination>
    )
  );
};

pagination.propTypes = {
  dataCount: PropTypes.number,
  paginationInfo: PropTypes.object,
  onPageChange: PropTypes.func,
};

export default pagination;
