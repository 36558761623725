import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  mediaItem: null,
  mediaImage: null,
  technicalInfo: null,
  formats: null,
  colourProfile: null,
  recommendedDataFormat: null,
  validDataFormats: null,
  error: false,
  errorMessage: "",
  issues: null,
  issueNumber: null,
  bleedInfo: null,
  printMaterialDeadline: null,
  publicationDate: null,
  sizes: null,
  mediaBindingMethod: null,
  bunddoppelungUmschlag: null,
  bunddoppelungInnenteil: null,
  istTeilbelegungMoeglich: null,
  selectedIssue: null,
  contactPersons: null
};

const leaveMediaDetail = (state, action) => {
  return updateObject(state, {
    ...initialState
  });
};

const setMediaDetail = (state, action) => {
  return updateObject(state, {
    mediaItem: action.mediaItem,
    mediaImage: action.mediaImage,
    error: false,
    errorMessage: ""
  });
};

const setMediaDetailTechnicalInfo = (state, action) => {
  return updateObject(state, {
    issueNumber: action.issueNumber,
    technicalInfo: action.technicalInfo,
    bleedInfo: action.bleedInfo,
    formats: action.formats,
    colourProfile: action.colourProfile,
    printMaterialDeadline: action.printMaterialDeadline,
    publicationDate: action.publicationDate,
    validDataFormats: action.validDataFormats,
    recommendedDataFormat: action.recommendedDataFormat,
    sizes: action.sizes,
    mediaBindingMethod: action.mediaBindingMethod,
    bunddoppelungUmschlag: action.bunddoppelungUmschlag,
    bunddoppelungInnenteil: action.bunddoppelungInnenteil,
    istTeilbelegungMoeglich: action.istTeilbelegungMoeglich,
    error: false,
    errorMessage: ""
  });
};

const setMediaDetailIssues = (state, action) => {
  return updateObject(state, {
    issues: action.issues
  });
};

const setMediaDetailSelectedIssue = (state, action) => {
  return updateObject(state, {
    selectedIssue: action.selectedIssue
  });
};

const setMediaDetailContactPersons = (state, action) => {
  return updateObject(state, {
    contactPersons: action.contactPersons
  });
};

const fetchMediaDetailFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Media details can't be loaded"
  });
};

const fetchMediaDetailTechnicalInfoFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical Info can't be loaded"
  });
};

const setActualizedMediaDetailTechnicalInfo = (state, action) => {
  return updateObject(state, {
    technicalInfo: action.technicalInfo
  });
};

const actualizeMediaDetailTechnicalInfoFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical Info kann nicht geladen werden"
  });
};

const downloadTechnicalInfoFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical Info can't be downloaded"
  });
};

const fetchTechnicalURLInfoFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Technical URL Info can't be loaded"
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEDIA_DETAIL:
      return setMediaDetail(state, action);
    case actionTypes.SET_MEDIA_DETAIL_ISSUE:
      return setMediaDetailTechnicalInfo(state, action);
    case actionTypes.SET_MEDIA_DETAIL_SELECTED_ISSUE:
      return setMediaDetailSelectedIssue(state, action);
    case actionTypes.SET_MEDIA_DETAIL_ISSUES:
      return setMediaDetailIssues(state, action);
    case actionTypes.SET_MEDIA_DETAIL_CONTACT_PERSON:
      return setMediaDetailContactPersons(state, action);
    case actionTypes.FETCH_MEDIA_DETAIL_FAIL:
      return fetchMediaDetailFailed(state, action);
    case actionTypes.FETCH_MEDIA_DETAIL_TECHNICAL_INFO_FAIL:
      return fetchMediaDetailTechnicalInfoFailed(state, action);
    case actionTypes.SET_ACTUALIZED_MEDIA_DETAIL_TECHNICAL_INFO:
      return setActualizedMediaDetailTechnicalInfo(state, action);
    case actionTypes.ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO_FAIL:
      return actualizeMediaDetailTechnicalInfoFailed(state, action);
    case actionTypes.DOWNLOAD_TECHNICAL_INFO_FAIL:
      return downloadTechnicalInfoFail(state, action);
    case actionTypes.LEAVE_MEDIA_DETAIL:
      return leaveMediaDetail(state, action);
    case actionTypes.FETCH_TECHNICAL_URL_INFO_FAIL:
      return fetchTechnicalURLInfoFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
