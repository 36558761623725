import React, { Component } from "react";
import PropTypes from "prop-types";
import { InputGroup, Button, Input, Container, Row, Col } from "reactstrap";
import FontAwesome from "react-fontawesome";
import { translateAsLiteral } from "../../../helpers/utility";
import classes from "./HomeSearch.module.css";

class HomeSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    this.changeHandler = this.changeHandler.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.keyPressHandler = this.keyPressHandler.bind(this);
  }

  render() {
    const { appLanguage } = this.props;

    return (
      <div className={classes.Main}>
        <Container>
          <Row>
            <Col>
              <InputGroup>
                <Button
                  id="btn_homeSearch"
                  onClick={this.submitHandler}
                  className={classes.Button}
                >
                  <FontAwesome name="search" />
                </Button>
                <Input
                  onChange={this.changeHandler}
                  className={classes.Textbox}
                  placeholder={translateAsLiteral(
                    appLanguage,
                    "Home.Search.Label"
                  )}
                  onKeyPress={this.keyPressHandler}
                />
              </InputGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  changeHandler(event) {
    this.setState({ value: event.target.value });
  }

  submitHandler(event) {
    event.preventDefault();
    this.props.handleSubmit(this.state.value);
  }

  keyPressHandler(target) {
    if (target.charCode === 13) {
      this.submitHandler(target);
    }
  }
}

HomeSearch.propTypes = {
  handleSubmit: PropTypes.func,
  appLanguage: PropTypes.string,
};

export default HomeSearch;
