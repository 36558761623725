import API from "../../api";
import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";
import { errorHandler } from "../../helpers/Logger/Logger";
import {
  PUBLISHERS_ENDPOINT,
  PUBLISHER_DETAIL_ENDPOINT
} from "../../helpers/apiEndpoints";
import { translateAsLiteral } from "../../helpers/utility";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* fetchPublishersSaga(action) {
  try {

    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(publishersURL(action.searchKeyword));
    let paginationHeaders = null;

    if (response.headers["x-pagination"]) {
      paginationHeaders = JSON.parse(response.headers["x-pagination"]);
    }

    yield put(actions.setPublishers(response.data, paginationHeaders));
  } catch (error) {
    let errorMessage = "Publisher Items can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = translateAsLiteral(
        action.appLanguage,
        "Verlage.PublisherNotFound"
      );
    }
    yield put(actions.fetchPublishersFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchPublishers",
      publishersURL(action.searchKeyword)
    );
  }
}

export function* fetchPublisherDetailSaga(action) {
  try {
    yield put(actions.hidePublisherDetailResult());
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      publisherDetailURL(
        action.publisherId,
        action.searchKeyword,
        action.gridType
      )
    );
    let paginationHeaders = null;

    if (response.headers["x-pagination"]) {
      paginationHeaders = JSON.parse(response.headers["x-pagination"]);
    }

    yield put(actions.setPublisherDetail(response.data, paginationHeaders));
    yield put(actions.showPublisherDetailResult());
  } catch (error) {
    let errorMessage = "Publisher Details can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = translateAsLiteral(
        action.appLanguage,
        "Verlage.PublisherMediaNotFound"
      );
    }
    yield put(actions.fetchPublishersFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchPublisherDetail",
      publisherDetailURL(
        action.publisherId,
        action.searchKeyword,
        action.gridType
      )
    );
  }
}

const publishersURL = params => {
  let url =
    params.length > 0
      ? `${PUBLISHERS_ENDPOINT}?${params}`
      : `${PUBLISHERS_ENDPOINT}?Suchbegriff=*`;
  url = url + "&SeitenGroesse=70";
  return url;
};

const publisherDetailURL = (publisherId, params, gridType) => {
  let url =
    params.length > 0
      ? `${PUBLISHER_DETAIL_ENDPOINT.replace("{id}", publisherId)}?${params}`
      : `${PUBLISHER_DETAIL_ENDPOINT.replace(
          "{id}",
          publisherId
        )}?Suchbegriff=*`;
  if (gridType === "card") {
    url = url + "&SeitenGroesse=120";
  } else {
    url = url + "&SeitenGroesse=50";
  }
  return url;
};
