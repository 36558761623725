import PropTypes from "prop-types";
import React, { PureComponent } from "react";

import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "./Header.module.css";
import LoggedInNavigation from "./LoggedInNavigation/LoggedInNavigation";
import LoggedOutNavigation from "./LoggedOutNavigation/LoggedOutNavigation";
import LanguageSelector from "./LanguageSelector/LanguageSelector";
import Logo from "./Logo/Logo";

export class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showLanguages: false,
      isHomepageVisited: false
    };

    this.toggle = this.toggle.bind(this);
  }

  setVisitedStyle = () => {
    this.setState({
      isHomepageVisited: true
    });
  };

  handleLinkVisit = () => {
    this.setState({
      isHomepageVisited: false,
      isOpen: false
    });
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const { isOpen, isHomepageVisited } = this.state;
    const {
      isAuth,
      username,
      isReceiver,
      isCompanyAdmin,
      appLanguage,
      gridType,
      hasFreigabeRole,
      onEnterJobList,
      onLeaveJobList
    } = this.props;

    return (
      <header>
        <Navbar color="#000" dark expand="lg" className={classes.NavBar}>
          <Logo setVisitedStyle={() => this.setVisitedStyle()} />
          <div
            className={[classes.Version, "d-flex align-items-center"].join(" ")}
          >
            <a
              className={classes.VersionLink}
              href="https://duon-portal.blog/releases"
              target="_blank"
              rel="noopener noreferrer"
            >
              Version {process.env.REACT_APP_Version}
            </a>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <div className={classes.Version}>
            <div className={classes.VersionLink}>|</div>
          </div>
          <div>&nbsp;</div>
          <div>&nbsp;</div>
          <LanguageSelector
            isAuth={isAuth}
            appLanguage={appLanguage}
            onUpdateUserLanguage={this.props.onUpdateUserLanguage}
            onChangeLanguageSetting={this.props.onChangeLanguageSetting}
          />

          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              {isAuth && username ? (
                <LoggedInNavigation
                  isReceiver={isReceiver}
                  isCompanyAdmin={isCompanyAdmin}
                  appLanguage={appLanguage}
                  isHomepageVisited={isHomepageVisited}
                  gridType={gridType}
                  hasFreigabeRole={hasFreigabeRole}
                  visitLink={this.handleLinkVisit}
                  onEnterJobList={onEnterJobList}
                  onLeaveJobList={onLeaveJobList}
                />
              ) : (
                <LoggedOutNavigation
                  appLanguage={appLanguage}
                  isHomepageVisited={isHomepageVisited}
                  visitLink={this.handleLinkVisit}
                />
              )}
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCompanyAdmin: state.auth.isCompanyAdmin,
    isJobsMenuOpen: state.auth.isJobsMenuOpen,
    gridType: state.jobList.gridType,
    appLanguage: state.auth.appLanguage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateUserLanguage: language =>
      dispatch(actions.saveUserLanguage(language)),
    onChangeLanguageSetting: language =>
      dispatch(actions.changeLanguageSetting(language)),
    onLeaveJobList: () => dispatch(actions.leaveJobList()),
    onEnterJobList: gridType => dispatch(actions.enterJobList(gridType))
  };
};

NavbarBrand.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

Header.propTypes = {
  isAuth: PropTypes.bool,
  isReceiver: PropTypes.bool,
  username: PropTypes.string
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
