import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  certifiedItems: null,
  error: false,
  errorMessage: ""
};

const setCertifiedItems = (state, action) => {
  return updateObject(state, {
    certifiedItems: action.certifiedItems,
    error: false,
    errorMessage: ""
  });
};

const fetchCertifiedItemsFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CERTIFIED_ITEMS:
      return setCertifiedItems(state, action);
    case actionTypes.FETCH_DUON_CERTIFIED_FAILED:
      return fetchCertifiedItemsFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
