import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import { FormGroup } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";

const LoginInputs = props => {
  const {
    email,
    password,
    inputChangedHandler,
    resetClientErrors,
    appLanguage
  } = props;

  return (
    <FormGroup>
      <Input
        type={email.elementConfig.type}
        placeholder={appLanguage === "en" ? "E-Mail Address" : "E-Mail Adresse"}
        valid={email.valid}
        invalid={email.touched && email.valid === false}
        onChange={event => resetClientErrors(event, "email")}
        onBlur={event => inputChangedHandler(event, "email")}
        onFocus={e => (e.target.placeholder = "")}
      />
      {email.touched && email.valid === false && email.value !== "" && (
        <div className="invalid-feedback text-left">
          <Translate id="Auth.Login.Error.Email" />
        </div>
      )}
      {email.touched && email.valid === false && email.value === "" && (
        <div className="invalid-feedback text-left">
          <Translate id="Auth.Login.Error.Email.Empty" />
        </div>
      )}
      <br />
      <Input
        type={password.elementConfig.type}
        placeholder={appLanguage === "en" ? "Password" : "Passwort"}
        valid={password.valid}
        invalid={password.touched && password.valid === false}
        onChange={event => resetClientErrors(event, "password")}
        onBlur={event => inputChangedHandler(event, "password")}
        onFocus={e => (e.target.placeholder = "")}
      />
      {password.touched && password.valid === false && password.value !== "" && (
        <div className="invalid-feedback text-left">
          <Translate id="Auth.Login.Error.Passwort" />
        </div>
      )}
      {password.touched && password.valid === false && password.value === "" && (
        <div className="invalid-feedback text-left">
          <Translate id="Auth.Login.Error.Passwort.Empty" />
        </div>
      )}
    </FormGroup>
  );
};

LoginInputs.propTypes = {
  email: PropTypes.object,
  password: PropTypes.object,
  appLanguage: PropTypes.string,
  inputChangedHandler: PropTypes.func
};

export default LoginInputs;
