import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import Translate from "../../../hoc/Locale/Translate";
import { Modal, PrimaryButton, SecondaryButton } from "../../UI";

const CertificationCancelModal = props => {
  const { showCancelModal, handleCancelConfirm, handleCancelAbort } = props;

  return (
    <Modal
      onHideModal={handleCancelAbort}
      isOpen={showCancelModal}
      modalHeader={
        <div>
          <FontAwesome name="comments" />
          &nbsp;
          <span>DUON-CERTIFIED-PROGRAM</span>
        </div>
      }
      modalBody={<Translate id="Certification.DisregardMessage" />}
      modalFooter={
        <>
          <SecondaryButton
            text={<Translate id="General.No" />}
            onClick={handleCancelAbort}
          ></SecondaryButton>
          <PrimaryButton
            text={<Translate id="General.Yes" />}
            onClick={handleCancelConfirm}
          ></PrimaryButton>
        </>
      }
    />
  );
};

CertificationCancelModal.propTypes = {
  showCancelModal: PropTypes.bool,
  handleCancelConfirm: PropTypes.func,
  handleCancelAbort: PropTypes.func
};

export default CertificationCancelModal;
