import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";

const LoginFooter = props => {
  const { toggleCannotLoginButtons } = props;

  return (
    <Row>
      <Col md="6">
        <Button block disabled={false} color="primary">
          <Translate id="Auth.Login" />
        </Button>
      </Col>
      <Col md="6" className="text-right">
        <Button color="link" onClick={toggleCannotLoginButtons}>
          <Translate id="Auth.NotWorking" />
        </Button>
      </Col>
    </Row>
  );
};

LoginFooter.propTypes = {
  toggleCannotLoginButtons: PropTypes.func
};

export default LoginFooter;
