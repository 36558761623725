import React, { Component } from "react";
import { withRouter } from "../../../helpers/utility";
import PropTypes from "prop-types";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./Statistics.module.css";

export class Statistics extends Component {
  render() {
    const { portalStatus, appLanguage } = this.props;

    return (
      <div className={classes.StatisticSection}>
        <div
          className={classes.StatisticBox}
          onClick={() => this.props.navigation("/verlage")}
        >
          <div className={classes.Number}>
            {portalStatus &&
              parseInt(portalStatus.verlageStatus).toLocaleString("de-DE")}
          </div>
          <div>
            <Translate id="Home.Statistics.Verlage" />
          </div>
        </div>
        <div
          className={classes.StatisticBox}
          onClick={() => this.props.navigation("/medien")}
        >
          <div className={classes.Number}>
            {portalStatus &&
              parseInt(portalStatus.medienStatus).toLocaleString("de-DE")}
          </div>
          <div>
            <Translate id="Home.Statistics.Medien" />
          </div>
        </div>
        <div
          className={classes.StatisticBox}
          onClick={() => this.props.navigation("/jobs")}
        >
          <div className={classes.Number}>
            {portalStatus &&
              (
                parseInt(portalStatus.anzeigenStatus.replace(".", "")) + 912000
              ).toLocaleString(appLanguage === "en" ? "en-US" : "de-DE")}
          </div>
          <div>
            <Translate id="Home.Statistics.Anzeigen" />
          </div>
        </div>
        <div className={classes.StatisticBox} style={{ cursor: "default" }}>
          <div className={classes.Number}>
            {portalStatus &&
              parseInt(portalStatus.firmenStatus).toLocaleString(
                appLanguage === "en" ? "en-US" : "de-DE"
              )}
          </div>
          <div>
            <Translate id="Home.Statistics.Firmen" />
          </div>
        </div>
      </div>
    );
  }
}

Statistics.propTypes = {
  portalStatus: PropTypes.object,
  appLanguage: PropTypes.string
};

export default withRouter(Statistics);
