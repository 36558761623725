import axios from "axios";

let IDENTITY_SERVER_API_URL;

if (window.location.href.indexOf("localhost") > -1) {
  IDENTITY_SERVER_API_URL = "https://localhost:8131";
 // IDENTITY_SERVER_API_URL = "https://login-dev.duon-portal.de";
} else if (window.location.href.indexOf("dev.duon-portal.de") > -1) {
  IDENTITY_SERVER_API_URL = "https://login-dev.duon-portal.de";
} else if (window.location.href.indexOf("staging.duon-portal.de") > -1) {
  IDENTITY_SERVER_API_URL = "https://login-staging.duon-portal.de";
} else if (
  window.location.href.indexOf("ui-duon-staging.azurewebsites.net") > -1
) {
  IDENTITY_SERVER_API_URL = "https://login-staging.duon-portal.de";
} else if (window.location.href.indexOf("ui-duon.azurewebsites.net") > -1) {
  IDENTITY_SERVER_API_URL = "https://login.duon-portal.de";
} else {
  IDENTITY_SERVER_API_URL = "https://login.duon-portal.de";
}

const instance = axios.create({
  baseURL: IDENTITY_SERVER_API_URL ? IDENTITY_SERVER_API_URL : null
});

instance.defaults.headers.common["Authorization"] =
  "Basic RHVvbk5HLlJlYWN0LldlYlVJOjRWP3hRd1pBazY4";
instance.defaults.headers.post["Content-Type"] = "multipart/form-data";

export default instance;
