import React, { Component } from "react";
import { withRouter } from "../../helpers/utility";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import Spinner from "../../components/UI/Spinner/Spinner";
import { checkValidity, updateObject } from "../../helpers/utility";
import SendConfirmEmailLinkForm from "../../components/User/SendConfirmEmailLinkForm/SendConfirmEmailLinkForm";
import ForgotPasswordForm from "../../components/User/ForgotPasswordForm/ForgotPasswordForm";

import classes from "./Auth.module.css";
import Translate from "../../hoc/Locale/Translate";
import LoginInputs from "../../components/Login/LoginInputs/LoginInputs";
import LoginHeader from "../../components/Login/LoginHeader/LoginHeader";
import LoginFooter from "../../components/Login/LoginFooter/LoginFooter";
import CannotLogin from "../../components/Login/CannotLogin/CannotLogin";
import {
  Form
} from "reactstrap";


class Auth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showCannotLoginButtons: false,
      confirmEmailFormOpen: false,
      forgotPasswordFormOpen: false,
      email: {
        elementType: "input",
        elementConfig: {
          type: "email"
        },
        value: "",
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        elementType: "input",
        elementConfig: {
          type: "password"
        },
        value: "",
        validation: {
          required: true,
          minLength: 6
        },
        valid: false,
        touched: false
      },
      isSignup: true,
      values: {
        email: "",
        password: ""
      },
      validate: {
        emailState: "",
        passwordState: ""
      }
    };

    this.toggleCannotLoginButtons = this.toggleCannotLoginButtons.bind(this);
    this.toggleConfirmEmailForm = this.toggleConfirmEmailForm.bind(this);
    this.toggleForgotPasswordForm = this.toggleForgotPasswordForm.bind(this);
    this.handleConfirmEmailFormValidSubmit = this.handleConfirmEmailFormValidSubmit.bind(
      this
    );
    this.handleForgotPasswordFormValidSubmit = this.handleForgotPasswordFormValidSubmit.bind(
      this
    );
  }
  

  componentDidUpdate(prevProps) {
    const token = localStorage.getItem("token");
    if (
      (this.props.isAuthenticated !== prevProps.isAuthenticated &&
        this.props.isAuthenticated) ||
      token
    ) {
      this.props.navigation(this.props.authRedirectPath ? this.props.authRedirectPath : "/");
    }
  }

  componentDidMount() {
    const { authRedirectPath, onSetAuthRedirectPath, onResetAuth } = this.props;

    if (authRedirectPath === "/") {
      onSetAuthRedirectPath();
    }
    onResetAuth();
  }

  toggleConfirmEmailForm() {
    this.setState({
      confirmEmailFormOpen: !this.state.confirmEmailFormOpen,
      forgotPasswordFormOpen: false
    });
  }

  toggleForgotPasswordForm() {
    this.setState({
      forgotPasswordFormOpen: !this.state.forgotPasswordFormOpen,
      confirmEmailFormOpen: false
    });
  }

  toggleCannotLoginButtons() {
    this.setState({
      showCannotLoginButtons: !this.state.showCannotLoginButtons,
      confirmEmailFormOpen: false,
      forgotPasswordFormOpen: false
    });
  }

  handleConfirmEmailFormValidSubmit(event) {
    event.preventDefault();
    this.props.sendEmailConfirmation(this.state.values.email);
  }

  handleForgotPasswordFormValidSubmit(event) {
    event.preventDefault();
    this.props.sendPasswordResetLinkRequest(this.state.values.email);
  }

  inputChangedHandler = (event, controlName) => {
    if (controlName === "email") {
      const updatedEmail = updateObject(this.state.email, {
        value: event.target.value,
        valid: checkValidity(event.target.value, this.state.email.validation),
        touched: true
      });

      this.setState({ email: updatedEmail });
    } else if (controlName === "password") {
      const updatedPassword = updateObject(this.state.password, {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          this.state.password.validation
        ),
        touched: true
      });

      this.setState({ password: updatedPassword });
    }
  };

  resetClientErrors = (event, controlName) => {
    if (controlName === "email") {
      const updatedEmail = updateObject(this.state.email, {
        value: event.target.value,
        valid: true,
        touched: true
      });

      this.setState({ email: updatedEmail });
    } else if (controlName === "password") {
      const updatedPassword = updateObject(this.state.password, {
        value: event.target.value,
        valid: true,
        touched: true
      });

      this.setState({ password: updatedPassword });
    }
  };

  submitHandler = event => {
    event.preventDefault();
    const updatedEmail = updateObject(this.state.email, {
      value: this.state.email.value,
      valid: checkValidity(this.state.email.value, this.state.email.validation),
      touched: true
    });

    const updatedPassword = updateObject(this.state.password, {
      value: this.state.password.value,
      valid: checkValidity(
        this.state.password.value,
        this.state.password.validation
      ),
      touched: true
    });

    this.setState({ email: updatedEmail, password: updatedPassword });

    if (
      checkValidity(this.state.email.value, this.state.email.validation) &&
      checkValidity(this.state.password.value, this.state.password.validation)
    ) {
      this.props.onAuth(this.state.email.value, this.state.password.value);
    }
  };

  redirectToRegister = e => {
    this.props.navigation("/register");
  };

  UpdateValidateHandler = (name, value) => {
    this.setState(prevState => ({
      validate: {                   
          ...prevState.validate,    
          [name]: value,      
      }
    }));
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState(prevState => ({
      values: {                   
          ...prevState.values,    
          [name]: value,      
      }
    }));
  };

  render() {
    const {
      loading,
      error,
      sendEmailConfirmationSuccess,
      sendEmailConfirmationError,
      appLanguage,
      passwordResetLinkRequestSuccess,
      passwordResetLinkRequestError
    } = this.props;
    const {
      showCannotLoginButtons,
      confirmEmailFormOpen,
      forgotPasswordFormOpen,
      email,
      password
    } = this.state;

    let isFormValid = email.valid && password.valid;

    return (
      <>
        <div className={classes.Auth}>
          {error && (
            <div className={classes.Error}>
              <p>
                <Translate id="Auth.UnsuccessfulLogin" />
              </p>
            </div>
          )}
          <Form onSubmit={this.submitHandler}>
            <LoginHeader redirectToRegister={this.redirectToRegister} />
            {loading ? (
              <Spinner />
            ) : (
              <LoginInputs
                email={email}
                password={password}
                inputChangedHandler={this.inputChangedHandler}
                resetClientErrors={this.resetClientErrors}
                appLanguage={appLanguage}
              />
            )}
            <br />
            <LoginFooter
              isFormValid={isFormValid}
              toggleCannotLoginButtons={this.toggleCannotLoginButtons}
            />
          </Form>

          {showCannotLoginButtons && (
            <CannotLogin
              toggleConfirmEmailForm={this.toggleConfirmEmailForm}
              toggleForgotPasswordForm={this.toggleForgotPasswordForm}
            />
          )}
        </div>

        <SendConfirmEmailLinkForm
          isOpen={confirmEmailFormOpen}
          toggle={this.toggleConfirmEmailForm}
          success={sendEmailConfirmationSuccess}
          error={sendEmailConfirmationError}
          handleValidSubmit={this.handleConfirmEmailFormValidSubmit}
          appLanguage={appLanguage}
          values={this.state.values}
          validate={this.state.validate}
          onUpdateValidate={this.UpdateValidateHandler}
          onChange={(e) => this.handleChange(e)}
        />

        <ForgotPasswordForm
          isOpen={forgotPasswordFormOpen}
          toggle={this.toggleForgotPasswordForm}
          success={passwordResetLinkRequestSuccess}
          error={passwordResetLinkRequestError}
          handleValidSubmit={this.handleForgotPasswordFormValidSubmit}
          appLanguage={appLanguage}
          values={this.state.values}
          validate={this.state.validate}
          onUpdateValidate={this.UpdateValidateHandler}
          onChange={(e) => this.handleChange(e)}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath,
    passwordResetLinkRequestSuccess: state.auth.passwordResetLinkRequestSuccess,
    passwordResetLinkRequestError: state.auth.passwordResetLinkRequestError,
    sendEmailConfirmationSuccess: state.auth.sendEmailConfirmationSuccess,
    sendEmailConfirmationError: state.auth.sendEmailConfirmationError,
    appLanguage: state.auth.appLanguage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath("/")),
    onResetAuth: () => dispatch(actions.resetAuth()),
    sendEmailConfirmation: formData =>
      dispatch(actions.sendEmailConfirmation(formData)),
    sendPasswordResetLinkRequest: email =>
      dispatch(actions.sendPasswordResetLinkRequest(email))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
);
