async function saveLog(
  level,
  type,
  details,
  message,
  componentStack,
  apiEndpoint
) {
  window.appInsights.trackException(
    message,
    null,
    {
      Type: type,
      Details: details,
      ComponentStack: componentStack,
      "API Endpoint": apiEndpoint
    },
    null,
    level
  );
}

async function saveErrorBoundaryLog(
  level,
  type,
  message,
  userId,
  userName,
  userEmail,
  componentStack
) {
  window.appInsights.trackException(
    message,
    null,
    {
      Type: type,
      "User ID": userId,
      "User Name": userName,
      "User Email": userEmail,
      ComponentStack: componentStack
    },
    null,
    level
  );
}

export const errorBoundaryHandler = (
  message,
  type,
  userId,
  userName,
  userEmail,
  componentStack
) => {
  saveErrorBoundaryLog(
    3,
    message,
    type,
    userId,
    userName,
    userEmail,
    componentStack
  );
};

export const errorHandler = (
  message,
  type,
  details,
  component,
  apiEndpoint
) => {
  saveLog(2, message, type, details, component, apiEndpoint);
};
