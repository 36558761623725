import React from "react";
import PropTypes from "prop-types";
import EmailField from "../../Register/EmailField/EmailField";
import Translate from "../../../hoc/Locale/Translate";
import { translateAsLiteral } from "../../../helpers/utility";
import {
  Form,
  Button,
  Alert
} from "reactstrap";

const sendConfirmEmailLinkForm = (props) => {
  const { success, error, isOpen, toggle, appLanguage, handleValidSubmit } =
    props;
  let colour = "warning",
    successMessage,
    errorMessage;

  if (success) {
    colour = "success";
    successMessage = (
      <p>
        <Translate id="SendConfirmEmail.Success" />
      </p>
    );
  } else if (error) {
    colour = "danger";
    errorMessage = (
      <p>
        <Translate id="SendConfirmEmail.Error" />
      </p>
    );
  }

  const headerText = props.doMinimaliseText ? (
    <h5 style={{ textTransform: "none" }}>
      <Translate id="SendConfirmEmail.Header.MinimaliseText" />
    </h5>
  ) : (
    <h4 className="alert-heading">
      <Translate id="SendConfirmEmail.Header" />
    </h4>
  );

  return (
    <Alert
      className="mx-auto w-50"
      color={colour}
      isOpen={isOpen}
      toggle={toggle}
    >
      {headerText}
      {successMessage}
      {errorMessage}

      <Form onSubmit={handleValidSubmit}>
          <EmailField
            label={translateAsLiteral(appLanguage, "ForgotPassword.YourEmail")}
            appLanguage={appLanguage}
            validate={props.validate}
            onUpdateValidate={props.onUpdateValidate}
            onChange={(e) =>props.onChange(e)}
            value={props.values.email}
          />

        {!props.doMinimaliseText && (
          <div>
            <Translate id="SendConfirmEmail.Hint" />
            <br />
          </div>
        )}

        <Button className="my-2" color="primary">
          <Translate id="SendConfirmEmail.Button" />
        </Button>
      </Form>
    </Alert>
  );
};

sendConfirmEmailLinkForm.propTypes = {
  appLanguage: PropTypes.string.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  isOpen: PropTypes.bool,
  doMinimaliseText: PropTypes.bool,
  toggle: PropTypes.func,
  handleValidSubmit: PropTypes.func,
};

export default sendConfirmEmailLinkForm;
