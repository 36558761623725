import React from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import PropTypes from "prop-types";

export default class BootstrapDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.select = this.select.bind(this);
    this.state = {
      dropdownOpen: false,
      value: this.props.header
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  select(event) {
    const val = event.currentTarget.textContent;
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      value: val
    });

    this.props.onDropdownItemSelect(event);
  }

  render() {
    const { items, color } = this.props;
    const { dropdownOpen, value } = this.state;

    return (
      <Dropdown isOpen={dropdownOpen} toggle={this.toggle}>
        <DropdownToggle caret color={color ? color : "primary"}>
          {value}
        </DropdownToggle>
        <DropdownMenu>
          {items.map((item, i) => {
            return (
              <DropdownItem onClick={this.select} key={"dropdown" + i}>
                {item}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

BootstrapDropdown.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  items: PropTypes.array.isRequired,
  onDropdownItemSelect: PropTypes.func,
  color: PropTypes.string
};
