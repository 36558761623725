import * as actionTypes from "./actionTypes";
import API from "../../api";
import privateAPI from "../../privateApi";
import * as apiEndpoints from "../../helpers/apiEndpoints";

export const addFileToNewJob = (
  file,
  containerId,
  jobData,
  container,
  activeJobSection
) => {
  return {
    type: actionTypes.ADD_FILE_TO_NEW_JOB,
    containerId: containerId,
    file: file,
    jobData: jobData,
    container: container,
    activeJobSection: activeJobSection
  };
};

export const addFileToNewJobFailed = () => {
  return {
    type: actionTypes.ADD_FILE_TO_NEW_JOB_FAILED
  };
};

export const addFileToExistingJob = (file, jobId, job, activeJobSection) => {
  return {
    type: actionTypes.ADD_FILE_TO_EXISTING_JOB,
    jobId: jobId,
    file: file,
    formData: job,
    activeJobSection: activeJobSection
  };
};

export const addFileToExistingJobFailed = () => {
  return {
    type: actionTypes.ADD_FILE_TO_EXISTING_JOB_FAIL
  };
};

export const addParentOrphanJobs = (containerId, container) => {
  return {
    type: actionTypes.ADD_PARENT_ORPHAN_JOBS,
    containerId: containerId,
    container: container
  };
};

export const checkJobStatus = (
  jobId,
  retries,
  maxRetries,
  hasJobImage,
  activeJobSection
) => {
  return {
    type: actionTypes.CHECK_JOB_STATUS,
    jobId: jobId,
    retries: retries,
    maxRetries: maxRetries,
    hasJobImage: hasJobImage,
    activeJobSection: activeJobSection
  };
};

export const checkJobStatusFail = activeJobSection => {
  //this.hasJobImage = null;
  return {
    type: actionTypes.CHECK_JOB_STATUS_FAIL,
    activeJobSection: activeJobSection
  };
};

export const checkJobStatusFileError = activeJobSection => {
  // this.hasJobImage = null;
  return {
    type: actionTypes.CHECK_JOB_STATUS_FILE_ERROR,
    activeJobSection: activeJobSection,
    error: true
  };
};

export const createContainerFailed = () => {
  return {
    type: actionTypes.CREATE_CONTAINER_FAIL
  };
};

export const createContainerManyJobs = (
  containerJobs,
  containerFormData,
  containerMedia,
  file,
  newJobForm,
  activeJobSection,
  container
) => {
  return {
    type: actionTypes.CREATE_CONTAINER_MANY_JOBS,
    containerJobs: containerJobs,
    containerFormData: containerFormData,
    containerMedia: containerMedia,
    file: file,
    newJobForm: newJobForm,
    activeJobSectionId: activeJobSection.id,
    mediumId: activeJobSection.mediaItem.mediumId,
    activeJobSection: activeJobSection,
    container: container
  };
};

export const createContainerManyJobsSplit = (
  containerJobs,
  containerFormData,
  containerMedia,
  file,
  newJobForm,
  activeJobSection,
  container
) => {
  return {
    type: actionTypes.CREATE_CONTAINER_MANY_JOBS_SPLIT,
    containerJobs: containerJobs,
    containerFormData: containerFormData,
    containerMedia: containerMedia,
    file: file,
    newJobForm: newJobForm,
    activeJobSectionId: activeJobSection.id,
    mediumId: activeJobSection.mediaItem.mediumId,
    activeJobSection: activeJobSection,
    container: container
  };
};

export const createContainerOneJob = (formData, file, activeJobSection) => {
  return {
    type: actionTypes.CREATE_CONTAINER_ONE_JOB,
    formData: formData,
    file: file,
    activeJobSection: activeJobSection
  };
};

export const createContainerOneJobFailed = () => {
  return {
    type: actionTypes.CREATE_CONTAINER_ONE_JOB_FAIL
  };
};

export const deleteContainerJob = (containerId, jobId) => {
  return {
    type: actionTypes.DELETE_JOB_JOBBUILDER,
    jobId: jobId,
    containerId: containerId
  };
};

export const deleteChildJobs = (
  parentId,
  parentOrder,
  mediumId,
  parentFormatMD5Hash,
  container,
  containerId
) => {
  return {
    type: actionTypes.DELETE_CHILD_JOBS,
    parentId: parentId,
    parentOrder: parentOrder,
    mediumId: mediumId,
    parentFormatMD5Hash: parentFormatMD5Hash,
    container: container,
    containerId: containerId
  };
};

export const downloadReport = jobId => {
  return {
    type: actionTypes.DOWNLOAD_REPORT,
    jobId: jobId
  };
};

export const downloadReportFailed = () => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_FAIL
  };
};

export const downloadPDF = jobId => {
  return {
    type: actionTypes.DOWNLOAD_PDF,
    jobId: jobId
  };
};

export const downloadPDFFailed = () => {
  return {
    type: actionTypes.DOWNLOAD_PDF_FAIL
  };
};

export const editJobBuilder = (containerId, jobId, appLanguage) => {
  return {
    type: actionTypes.EDIT_JOB_BUILDER,
    containerId: containerId,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const getJobContactPersons = (containerId, jobId, appLanguage) => {
  return {
    type: actionTypes.GET_JOB_CONTACTPERSONS,
    containerId: containerId,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const finalizeJobUpload = (statusId, activeJobSection) => {
  return {
    type: actionTypes.FINIALIZE_JOB_UPLOAD,
    statusId: statusId,
    activeJobSection: activeJobSection
  };
};

export const initJobBuilder = (mediaId, datum, formatMD5Hash, appLanguage) => {
  return {
    type: actionTypes.INIT_JOB_BUILDER,
    mediaId: mediaId,
    datum: datum,
    formatMD5Hash: formatMD5Hash,
    appLanguage: appLanguage
  };
};

export const leaveJobBuilder = () => {
  return {
    type: actionTypes.LEAVE_JOB_BUILDER
  };
};

export const fetchContainerSuccess = (
  jobData,
  client,
  product,
  containerNotes
) => {
  let preflightErgebnis = {
    containerId: jobData.containerId,
    auftragsId: jobData.id,
    statusId: jobData.statusId,
    status: jobData.status,
    ergebnisId: jobData.preflightErgebnisId,
    ergebnis: jobData.preflightErgebnis
  };

  return {
    type: actionTypes.FETCH_CONTAINER_SUCCESS,
    jobId: jobData.id,
    preflightErgebnis: preflightErgebnis,
    statusId: jobData.statusId,
    client: client,
    product: product,
    motive: jobData.motiv,
    containerNotes: containerNotes,
    containerId: jobData.containerId,
    fileName: jobData.duDateiNameOriginal
  };
};

export const fetchContainerFailed = () => {
  return {
    type: actionTypes.FETCH_CONTAINER_FAIL
  };
};

export const fetchMedia = (
  mediaId,
  datum,
  activeJobSectionId,
  formatMD5Hash,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_JOBBUILDER_MEDIA,
    mediaId: mediaId,
    datum: datum,
    activeJobSectionId: activeJobSectionId,
    formatMD5Hash: formatMD5Hash,
    appLanguage: appLanguage
  };
};

export const fetchMediaFailed = () => {
  return {
    type: actionTypes.FETCH_MEDIA_FAIL
  };
};

export const fetchMediaEditMode = (
  mediaId,
  datum,
  activeJobSectionId,
  jobId
) => {
  return {
    type: actionTypes.FETCH_MEDIA_EDIT_MODE,
    mediaId: mediaId,
    datum: datum,
    activeJobSectionId,
    jobId: jobId
  };
};

export const fetchParentMediaEditMode = (mediaId, datum, job) => {
  return {
    type: actionTypes.FETCH_PARENT_MEDIA_EDIT_MODE,
    mediaId: mediaId,
    datum: datum,
    job: job
  };
};

export const fetchJobIssues = (mediaId, activeJobSectionId, appLanguage) => {
  return {
    type: actionTypes.FETCH_JOB_ISSUES,
    mediaId: mediaId,
    activeJobSectionId: activeJobSectionId,
    appLanguage: appLanguage
  };
};

export const fetchJobIssuesFailed = () => {
  return {
    type: actionTypes.FETCH_JOB_ISSUES_FAIL
  };
};

export const fetchTechnicalData = (
  issues,
  mediaId,
  selectedIssue,
  activeJobSectionId,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_TECHNICAL_DATA,
    issues: issues,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    activeJobSectionId: activeJobSectionId,
    appLanguage: appLanguage
  };
};

export const fetchJobDates = (
  mediaId,
  datum,
  activeJobSectionId,
  formatMD5Hash,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_JOB_DATES,
    mediaId: mediaId,
    datum: datum,
    activeJobSectionId: activeJobSectionId,
    formatMD5Hash: formatMD5Hash,
    appLanguage: appLanguage
  };
};

export const fetchJobDatesEditMode = (
  mediaId,
  datum,
  selectedFormat,
  job,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_JOB_DATES_EDIT_MODE,
    mediaId: mediaId,
    datum: datum,
    selectedFormat: selectedFormat,
    job: job,
    appLanguage: appLanguage
  };
};

export const fetchParentJobDatesEditMode = (
  mediaId,
  datum,
  job,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_PARENT_JOB_DATES_EDIT_MODE,
    mediaId: mediaId,
    datum: datum,
    job: job,
    appLanguage: appLanguage
  };
};

export const fetchTechnicalInfo = (
  mediaId,
  selectedIssue,
  activeJobSectionId,
  formatMD5Hash,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_TECHNICAL_INFO,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    activeJobSectionId: activeJobSectionId,
    formatMD5Hash: formatMD5Hash,
    appLanguage: appLanguage
  };
};

export const fetchTechnicalInfoFailed = () => {
  return {
    type: actionTypes.FETCH_TECHNICAL_INFO_FAIL
  };
};

export const fetchTechnicalInfoEditMode = (
  mediaId,
  selectedIssue,
  selectedFormat,
  job,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_TECHNICAL_INFO_EDIT_MODE,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    selectedFormat: selectedFormat,
    job: job,
    appLanguage: appLanguage
  };
};

export const fetchParentTechnicalInfoEditMode = (
  mediaId,
  selectedIssue,
  job,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_PARENT_TECHNICAL_INFO_EDIT_MODE,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    job: job,
    appLanguage: appLanguage
  };
};

export const fetchJobImage = (jobId, activeJobSection) => {
  return {
    type: actionTypes.FETCH_JOB_IMAGE,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const fetchJobImageFailed = () => {
  return {
    type: actionTypes.FETCH_JOB_IMAGE_FAIL
  };
};

export const proofFile = (
  jobId,
  activeJobSection,
  containerJobs,
  containerFormData,
  containerId,
  containerMedia
) => {
  return {
    type: actionTypes.PROOF_FILE,
    jobId: jobId,
    activeJobSection: activeJobSection,
    containerJobs: containerJobs,
    containerFormData: containerFormData,
    containerId: containerId,
    containerMedia: containerMedia
  };
};

export const proofContainer = (
  container,
  containerFormData,
  containerId,
  containerMedia
) => {
  return {
    type: actionTypes.PROOF_CONTAINER,
    container: container,
    containerFormData: containerFormData,
    containerId: containerId,
    containerMedia: containerMedia
  };
};

export const proofContainerFailed = containerId => {
  return {
    type: actionTypes.PROOF_CONTAINER_FAIL,
    containerId: containerId
  };
};

export const allowReProof = activeJobSection => {
  return {
    type: actionTypes.ALLOW_REPROOF,
    activeJobSection: activeJobSection
  };
};

export const proofFileStart = (jobId, activeJobSection) => {
  return {
    type: actionTypes.PROOF_FILE_START,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const proofFileSuccess = jobId => {
  return {
    type: actionTypes.PROOF_FILE_SUCCESS,
    jobId: jobId
  };
};

export const proofFileFail = () => {
  return {
    type: actionTypes.PROOF_FILE_FAIL
  };
};

export const resetFileUpload = () => {
  return {
    type: actionTypes.RESET_ERROR
  };
};

export const setFileImagePreview = source => {
  return {
    type: actionTypes.SET_FILE_IMAGE_PREVIEW,
    fileImageSource: source
  };
};

export const setMedia = (mediaId, data) => {
  let imageUrl = API.defaults.baseURL + mediaImageURL(mediaId);
  return {
    type: actionTypes.SET_MEDIA,
    mediaItem: data,
    mediaImage: imageUrl,
    jobId: null
  };
};

export const setMediaEditMode = (mediaId, data, jobId) => {
  let imageUrl = API.defaults.baseURL + mediaImageURL(mediaId);
  return {
    type: actionTypes.SET_MEDIA_EDIT_MODE,
    mediaItem: data,
    mediaImage: imageUrl,
    jobId: jobId
  };
};

export const setParentMediaEditMode = (mediaId, data, job) => {
  let imageUrl = API.defaults.baseURL + mediaImageURL(mediaId);
  return {
    type: actionTypes.SET_PARENT_MEDIA_EDIT_MODE,
    mediaItem: data,
    mediaImage: imageUrl,
    job: job
  };
};

export const setJobIssues = (data, mediumId) => {
  return {
    type: actionTypes.SET_JOB_ISSUES,
    issues: data,
    mediumId: mediumId
  };
};

export const setJobIssuesEditMode = (data, mediumId) => {
  return {
    type: actionTypes.SET_JOB_ISSUES_EDIT_MODE,
    issues: data,
    mediumId: mediumId
  };
};

export const setIssue = (
  issueFormats,
  chosenMD5Hash,
  selectedIssue,
  activeJobSectionId,
  mediumId,
  issueSizes,
  bunddoppelungInnenteil,
  bunddoppelungUmschlag,
  appLanguage
) => {
  return {
    type: actionTypes.SET_ISSUE,
    issueFormats: issueFormats,
    chosenMD5Hash: chosenMD5Hash,
    selectedIssue: selectedIssue,
    activeJobSectionId: activeJobSectionId,
    mediumId: mediumId,
    issueSizes: issueSizes,
    bunddoppelungInnenteil: bunddoppelungInnenteil,
    bunddoppelungUmschlag: bunddoppelungUmschlag,
    appLanguage: appLanguage
  };
};

export const setIssueEditMode = (
  issueFormats,
  chosenMD5Hash,
  selectedIssue,
  mediumId,
  job,
  issueSizes,
  bunddoppelungInnenteil,
  bunddoppelungUmschlag
) => {
  return {
    type: actionTypes.SET_ISSUE_EDIT_MODE,
    issueFormats: issueFormats,
    chosenMD5Hash: chosenMD5Hash,
    selectedIssue: selectedIssue,
    mediumId: mediumId,
    job: job,
    issueSizes: issueSizes,
    bunddoppelungInnenteil: bunddoppelungInnenteil,
    bunddoppelungUmschlag: bunddoppelungUmschlag
  };
};

export const setParentIssueEditMode = (
  issueFormats,
  selectedIssue,
  mediumId,
  job
) => {
  return {
    type: actionTypes.SET_PARENT_ISSUE_EDIT_MODE,
    issueFormats: issueFormats,
    selectedIssue: selectedIssue,
    mediumId: mediumId,
    job: job
  };
};

export const setContactPersonState = (contactPersons, mediumId) => {
  return {
    type: actionTypes.SET_CONTACT_PERSON,
    contactPersons: contactPersons,
    mediumId: mediumId
  };
};

export const setContactsEditMode = (contactPersons, mediumId) => {
  return {
    type: actionTypes.SET_CONTACTS_EDIT_MODE,
    contactPersons: contactPersons,
    mediumId: mediumId
  };
};

export const saveNewJobWithoutFile = (
  containerId,
  jobData,
  activeJobSection
) => {
  return {
    type: actionTypes.SAVE_NEW_JOB_NO_FILE,
    containerId: containerId,
    jobData: jobData,
    activeJobSection: activeJobSection
  };
};

export const actualizeTechnicalInfo = (
  mediaId,
  selectedIssue,
  formatMD5Hash,
  activeJobSection,
  appLanguage,
  parentFormatMD5Hash
) => {
  return {
    type: actionTypes.ACTUALIZE_TECHNICAL_INFO,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    formatMD5Hash: formatMD5Hash,
    activeJobSection: activeJobSection,
    appLanguage: appLanguage,
    parentFormatMD5Hash: parentFormatMD5Hash
  };
};

export const actualizeTechnicalInfoFailed = () => {
  return {
    type: actionTypes.ACTUALIZE_TECHNICAL_INFO_FAIL
  };
};

export const setActualizedTechnicalInfo = technicalInfo => {
  return {
    type: actionTypes.SET_ACTUALIZED_TECHNICAL_INFO,
    technicalInfo: technicalInfo
  };
};

export const setContainer = container => {
  return {
    type: actionTypes.SET_CONTAINER,
    container: container
  };
};

export const setContainerId = containerId => {
  return {
    type: actionTypes.SET_CONTAINER_ID,
    containerId: containerId
  };
};

export const setContainerFirmenId = containerFirmenId => {
  return {
    type: actionTypes.SET_CONTAINER_FIRMEN_ID,
    containerFirmenId: containerFirmenId
  };
};

export const setMediaSections = mediaSections => {
  return {
    type: actionTypes.SET_MEDIA_SECTIONS,
    mediaSections: mediaSections
  };
};

export const setJobId = (jobId, activeJobSection) => {
  return {
    type: actionTypes.SET_JOB_ID,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const setParentJob = (jobId, activeJobSection) => {
  return {
    type: actionTypes.SET_PARENT_JOB,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const setChildJobId = (jobId, childJob) => {
  return {
    type: actionTypes.SET_CHILD_JOB_ID,
    jobId: jobId,
    childJob: childJob
  };
};

export const setNonChildJobId = (jobId, job) => {
  return {
    type: actionTypes.SET_NON_CHILD_JOB_ID,
    jobId: jobId,
    job: job
  };
};

export const setJobProgress = (jobId, activeJobSection) => {
  return {
    type: actionTypes.SET_JOB_PROGRESS,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const setJobFreeFormatText = (jobId, activeJobSection, formatText) => {
  return {
    type: actionTypes.SET_JOB_FREE_FORMAT_TEXT,
    jobId: jobId,
    activeJobSection: activeJobSection,
    formatText: formatText
  };
};

export const setJobStatusId = (jobId, activeJobSection) => {
  return {
    type: actionTypes.SET_JOB_STATUS_ID,
    jobId: jobId,
    activeJobSection: activeJobSection
  };
};

export const updateContainerFormData = formData => {
  return {
    type: actionTypes.SET_UPDATED_CONTAINER_FORM_DATA,
    containerFormData: formData
  };
};

export const setCreatedJobImage = (image, jobId) => {
  return {
    type: actionTypes.SET_CREATED_JOB_IMAGE,
    createdJobImage: image,
    jobId: jobId
  };
};

export const setContainerFormData = (client, product, notes) => {
  return {
    type: actionTypes.SET_CONTAINER_FORM_DATA,
    client: client,
    product: product,
    notes: notes
  };
};

export const setSelectedIssue = (
  jobId,
  activeJobSectionId,
  mediumId,
  selectedIssue
) => {
  return {
    type: actionTypes.SET_SELECTED_ISSUE,
    jobId: jobId,
    activeJobSectionId: activeJobSectionId,
    mediumId: mediumId,
    selectedIssue: selectedIssue
  };
};

export const saveFile = (jobId, file, activeJobSection) => {
  return {
    type: actionTypes.SAVE_FILE,
    jobId: jobId,
    file: file,
    activeJobSection: activeJobSection
  };
};

export const saveFileSuccess = (fileName, activeJobSection) => {
  return {
    type: actionTypes.SAVE_FILE_SUCCESS,
    fileName: fileName,
    activeJobSection: activeJobSection
  };
};

export const saveFileFailed = () => {
  return {
    type: actionTypes.SAVE_FILE_FAIL
  };
};

export const statusCheckSuccess = (data, activeJobSection) => {
  return {
    type: actionTypes.STATUS_CHECK_SUCCESS,
    preflightErgebnis: data,
    activeJobSection: activeJobSection
  };
};

export const saveJobFinish = (statusId, activeJobSection) => {
  //this.hasJobImage = null;
  return {
    type: actionTypes.SAVE_JOB_FINISH,
    statusId: statusId,
    activeJobSection: activeJobSection
  };
};

export const saveExistingContainer = (
  containerJobs,
  containerFormData,
  containerId,
  containerMedia
) => {
  return {
    type: actionTypes.SAVE_EXISTING_CONTAINER,
    containerJobs: containerJobs,
    containerFormData: containerFormData,
    containerId: containerId,
    containerMedia: containerMedia
  };
};

export const saveExistingContainerFailed = containerId => {
  return {
    type: actionTypes.SAVE_EXISTING_CONTAINER_FAIL,
    containerId: containerId
  };
};

export const saveNewContainer = (
  containerJobs,
  containerFormData,
  containerId,
  containerMedia
) => {
  return {
    type: actionTypes.SAVE_NEW_CONTAINER,
    containerJobs: containerJobs,
    containerFormData: containerFormData,
    containerId: containerId,
    containerMedia: containerMedia
  };
};

export const saveNewContainerFailed = () => {
  return {
    type: actionTypes.SAVE_NEW_CONTAINER_FAIL
  };
};

export const sendContainer = containerId => {
  return {
    type: actionTypes.SEND_CONTAINER,
    containerId: containerId
  };
};

export const startSendingContainerForm = () => {
  return {
    type: actionTypes.START_SENDING_CONTAINER_FORM
  };
};

export const checkSentStatus = (jobId, retries, maxRetries) => {
  return {
    type: actionTypes.CHECK_SENT_STATUS,
    jobId: jobId,
    retries: retries,
    maxRetries: maxRetries
  };
};

export const jobCheckSuccess = (statusResult, jobId) => {
  return {
    type: actionTypes.JOB_CHECK_SUCCESS,
    statusResult: statusResult,
    jobId: jobId
  };
};

export const jobCheckStart = jobId => {
  return {
    type: actionTypes.JOB_CHECK_START,
    jobId: jobId
  };
};

export const checkContainerIsSent = () => {
  return {
    type: actionTypes.CHECK_CONTAINER_IS_SENT
  };
};

export const showSavedSuccessMessage = () => {
  return {
    type: actionTypes.SHOW_CONTAINER_SAVED_SUCCESS_MESSAGE
  };
};

export const hideContainerSavedSuccessMessage = () => {
  return {
    type: actionTypes.HIDE_CONTAINER_SAVED_SUCCESS_MESSAGE
  };
};

export const toggleUploadComponent = activeJobSection => {
  return {
    type: actionTypes.TOGGLE_UPLOAD_COMPONENT,
    activeJobSection: activeJobSection
  };
};

export const updateContainer = (containerId, formData) => {
  return {
    type: actionTypes.UPDATE_CONTAINER,
    containerId: containerId,
    formData: formData
  };
};

export const updateContainerSuccess = () => {
  return {
    type: actionTypes.EDIT_CONTAINER_SUCCESS
  };
};

export const updateContainerFail = () => {
  return {
    type: actionTypes.EDIT_CONTAINER_FAIL
  };
};

export const updateJobSuccess = () => {
  return {
    type: actionTypes.EDIT_JOB_SUCCESS
  };
};

export const checkContainerCanBeSent = () => {
  return {
    type: actionTypes.CHECK_CONTAINER_CAN_BE_SENT
  };
};

export const addChildJobsToParent = (
  job,
  mediumId,
  chosenMD5Hash,
  splitOrder,
  parentIssueFormat
) => {
  return {
    type: actionTypes.ADD_CHILD_JOBS_TO_PARENT,
    job: job,
    mediumId: mediumId,
    chosenMD5Hash: chosenMD5Hash,
    splitOrder: splitOrder,
    parentIssueFormat: parentIssueFormat
  };
};

export const addAllChildJobsToParent = (
  job,
  mediumId,
  formatLeft,
  formatRight,
  parentIssueFormat
) => {
  return {
    type: actionTypes.ADD_ALL_CHILD_JOBS_TO_PARENT,
    job: job,
    mediumId: mediumId,
    formatLeft: formatLeft,
    formatRight: formatRight,
    parentIssueFormat: parentIssueFormat
  };
};

export const removeChildJobsFromParent = (
  jobId,
  jobSectionId,
  mediumId,
  splitOrder,
  parentOrder
) => {
  return {
    type: actionTypes.REMOVE_CHILD_JOBS_FROM_PARENT,
    jobId: jobId,
    jobSectionId: jobSectionId,
    mediumId: mediumId,
    splitOrder: splitOrder,
    parentOrder: parentOrder
  };
};

export const removeNormalJob = (job, mediumId) => {
  return {
    type: actionTypes.REMOVE_NORMAL_JOB,
    job: job,
    mediumId: mediumId
  };
};

export const addFreeFormat = (formData, activeJobSection) => {
  return {
    type: actionTypes.ADD_FREE_FORMAT,
    formData: formData,
    activeJobSection: activeJobSection
  };
};

export const editFreeFormat = activeJobSection => {
  return {
    type: actionTypes.EDIT_FREE_FORMAT,
    activeJobSection: activeJobSection
  };
};

export const disableSavingContainer = () => {
  return {
    type: actionTypes.DISABLE_SAVING_CONTAINER
  };
};

export const enableSavingContainer = () => {
  return {
    type: actionTypes.ENABLE_SAVING_CONTAINER
  };
};

export const fetchJobAnonymously = code => {
  return {
    type: actionTypes.FETCH_JOB_ANONYMOUSLY,
    code: code
  };
};

export const fetchJobAnonymouslyFailed = error => {
  return {
    type: actionTypes.FETCH_JOB_ANONYMOUSLY_FAILED,
    error: error
  };
};

export const setJobForAnonymousUser = (job, code) => {
  job.thumbnailImageUrl =
    privateAPI.defaults.baseURL + jobThumbnailImageURL(code);
  return {
    type: actionTypes.SET_JOB_FOR_ANONYMOUS_USER,
    job: job
  };
};

export const downloadJobFileAnonymously = (code, fileType) => {
  return {
    type: actionTypes.DOWNLOAD_JOB_FILE_ANONYMOUSLY,
    code: code,
    fileType: fileType
  };
};

export const downloadJobFileAnonymouslyFailed = error => {
  return {
    type: actionTypes.DOWNLOAD_JOB_FILE_ANONYMOUSLY_FAILED,
    error: error
  };
};

export const fetchSenderInfo = containerId => {
  return {
    type: actionTypes.FETCH_SENDER_INFO,
    containerId: containerId
  };
};

export const fetchSenderInfoFail = () => {
  return {
    type: actionTypes.FETCH_SENDER_INFO_FAIL
  };
};

export const setSenderInfo = (containerId, data) => {
  return {
    type: actionTypes.SET_SENDER_INFO,
    containerId: containerId,
    sender: data
  };
};

export const notifyJobChange = containerId => {
  return {
    type: actionTypes.NOTIFY_JOB_CHANGE,
    containerId: containerId
  };
};

const mediaImageURL = mediaId => {
  return apiEndpoints.MEDIA_IMAGE_ENDPOINT.replace("{id}", mediaId);
};

const jobThumbnailImageURL = code => {
  return apiEndpoints.DOWNLOAD_JOB_ANONYMOUSLY_THUMBNAIL_IMAGE_ENDPOINT.replace(
    "{code}",
    code
  );
};
