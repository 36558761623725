import React from "react";
import FontAwesome from "react-fontawesome";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./AnimatedContainerButton.module.css";

export const AnimatedContainerButton = props => {
  const {
    outline,
    disabled,
    isSendingContainer,
    onClick,
    size,
    block,
    icon
  } = props;
  const outlineClassName = outline === true ? classes.outline : "";

  let styleClass = disabled
    ? classes.FlyingButtonDisabled
    : [classes.AnimatedContainerButton, outlineClassName].join(" ");
  if (isSendingContainer) {
    styleClass = [classes.AnimatedContainerButtonSent, outlineClassName].join(
      " "
    );
  }
  return (
    <Button
      outline={outline}
      disabled={disabled}
      className={styleClass}
      onClick={onClick}
      size={size}
      block={block}
    >
      {icon && <FontAwesome name={icon} size="2x" />}
    </Button>
  );
};

AnimatedContainerButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  outline: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  isSendingContainer: PropTypes.bool
};
