import jobData from "./jobData";

const container = [
  {
    mediaItem: null,
    mediaImage: null,
    selectedIssue: null,
    issueFormats: null,
    chosenMD5Hash: null,
    jobId: null,
    jobProgress: null,
    stepNumber: 1,
    statusId: null,
    statusColor: null,
    preflightErgebnis: null,
    id: 1,
    displayNumber: "1",
    order: 1,
    originalFileName: null,
    createdJobImage: null,
    jobData,
    isUploadingFile: false,
    isFileProofRequired: false,
    isJobValid: false,
    isJobSaved: false,
    ausgetauschtDurchJobId: null,
    austauschContainerId: null,
    austauschFuerJobId: null
  }
];

export default container;
