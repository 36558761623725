import * as actionTypes from "./actionTypes";

export const fetchCertifiedItems = () => {
  return {
    type: actionTypes.FETCH_DUON_CERTIFIED
  };
};

export const fetchCertifiedItemsFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_DUON_CERTIFIED_FAILED,
    errorMessage: errorMessage
  };
};

export const setCertifiedItems = certifiedItems => {
  return {
    type: actionTypes.SET_CERTIFIED_ITEMS,
    certifiedItems: certifiedItems
  };
};
