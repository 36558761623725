import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  mediaItems: null,
  mediaItemNames: null,
  selectedMediaItem: null,
  error: false,
  paginationHeaders: null,
  errorMessage: "",
  issues: null
};

const setMediaItems = (state, action) => {
  return updateObject(state, {
    mediaItems: action.mediaItems,
    paginationHeaders: action.paginationHeaders,
    error: false,
    errorMessage: ""
  });
};

const setMediaItemNames = (state, action) => {
  /*   let mediaNames = action.mediaItems.map(item => {
    return item.name1;
  }); */

  return updateObject(state, {
    mediaItemNames: action.mediaItems,
    error: false,
    errorMessage: ""
  });
};

const fetchMediaItemsFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const setIssues = (state, action) => {
  return updateObject(state, {
    issues: action.issues,
    selectedMediaItem: action.mediaItemId,
    error: false,
    errorMessage: ""
  });
};

const fetchIssuesFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const leaveMediaItem = (state, action) => {
  return updateObject(state, {
    issues: null,
    selectedMediaItem: null,
    error: false,
    errorMessage: ""
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MEDIA_ITEMS:
      return setMediaItems(state, action);
    case actionTypes.FETCH_MEDIA_ITEMS_FAILED:
      return fetchMediaItemsFailed(state, action);
    case actionTypes.SET_MEDIA_ITEM_NAMES:
      return setMediaItemNames(state, action);
    case actionTypes.SET_ISSUES:
      return setIssues(state, action);
    case actionTypes.FETCH_ISSUES_FAILED:
      return fetchIssuesFailed(state, action);
    case actionTypes.LEAVE_MEDIA_ITEM:
      return leaveMediaItem(state, action);
    default:
      return state;
  }
};

export default reducer;
