import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./WarningButton.module.css";

const warningButton = props => {
  const { disabled, onClick, text } = props;

  return (
    <Button disabled={disabled} className={classes.Button} onClick={onClick}>
      {text}
    </Button>
  );
};

warningButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onClick: PropTypes.func
};

export default warningButton;
