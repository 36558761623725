import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import FontAwesome from "react-fontawesome";
import Translate from "../../../hoc/Locale/Translate";
import * as actions from "../../../store/actions";
import classes from "./Footer.module.css";

class Footer extends Component {
  render() {
    const { appLanguage, onDownloadFile } = this.props;
    return (
      <footer id="footer" className={classes.Footer}>
        <nav>
          <ul>
            <li>
              <FontAwesome name="envelope" />
              <a href="mailto:support@duon-portal.de">support@duon-portal.de</a>
            </li>
            <li>
              <FontAwesome name="phone" />
              <span>
                <a href="tel:+494037411750">+49 (0)40 / 374117-50</a>
              </span>
            </li>
            <li>
              <FontAwesome name="clock-o" />
              <Translate id="Footer.SupportTime" />
            </li>
          </ul>
          <ul>
            <li>
              <Link to={appLanguage === "en" ? "/imprint" : "/impressum"}>
                <Translate id="Footer.Impressum" />
              </Link>
            </li>
            <li>
              <Link to={appLanguage === "en" ? "/contactUs" : "/kontakt"}>
                <Translate id="Footer.Contact" />
              </Link>
            </li>
            <li>
              <Link
                to={appLanguage === "en" ? "/conventions" : "/Konventionen"}
              >
                <Translate id="Footer.Konventionen" />
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <button
                className={classes.Link}
                onClick={() =>
                  onDownloadFile(
                    appLanguage === "en"
                      ? "assets/pdf/20220401_Terms_of_Use.pdf"
                      : "assets/pdf/20220401_AGB_und_Nutzungsbedingungen.pdf",
                    "pdf",
                    appLanguage === "en"
                      ? "20220401_Terms_of_Use"
                      : "20220401_AGB_und_Nutzungsbedingungen"
                  )
                }
              >
                <Translate id="Footer.AGB" />
              </button>
            </li>
            <li>
              <button
                className={classes.Link}
                onClick={() =>
                  onDownloadFile(
                    appLanguage === "en"
                      ? "assets/pdf/20220401 DUON CERTIFIED PROGRAM Nutzungsbedingungen.pdf"
                      : "assets/pdf/20220401 DUON CERTIFIED PROGRAM Nutzungsbedingungen.pdf",
                    "pdf",
                    appLanguage === "en"
                      ? "20220401 DUON CERTIFIED PROGRAM TERMS OF USE"
                      : "20220401 DUON CERTIFIED PROGRAM Nutzungsbedingungen"
                  )
                }
              >
                <Translate id="Footer.Certified" />
              </button>
            </li>
            <li>
              <Link to={appLanguage === "en" ? "/dataprivacy" : "/datenschutz"}>
                <Translate id="Footer.Datenschutz" />
              </Link>
            </li>
          </ul>
        </nav>
        <div className={classes.MadeIn}>
          <a href="http://www.msw.de" rel="noopener noreferrer" target="_blank">
            Made in Hamburg <FontAwesome name="anchor" />
          </a>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appLanguage: state.auth.appLanguage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDownloadFile: (filePath, fileType, fileName) =>
      dispatch(actions.downloadFile(filePath, fileType, fileName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
