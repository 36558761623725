import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";
import API from "../../api";
import * as apiEndpoints from "../../helpers/apiEndpoints";

const initialState = {
  publishers: null,
  paginationHeaders: null,
  publisherMedia: null,
  error: false,
  errorMessage: ""
};

const setPublishers = (state, action) => {
  return updateObject(state, {
    publishers: action.publishers,
    paginationHeaders: action.paginationHeaders,
    error: false,
    errorMessage: ""
  });
};

const fetchPublishersFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const setPublisherDetail = (state, action) => {
  let publisherMedia = action.publisherMedia.map(medium => {
    return updateObject(medium, {
      mediaImage: API.defaults.baseURL + mediaImageURL(medium.msW_Id1)
    });
  });

  return updateObject(state, {
    publisherMedia: publisherMedia,
    paginationHeaders: action.paginationHeaders,
    error: false,
    errorMessage: ""
  });
};

const fetchPublisherDetailFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const leavePublishers = (state, action) => {
  return updateObject(state, {
    publishers: null,
    paginationHeaders: null,
    error: false,
    errorMessage: ""
  });
};

const leavePublisherDetail = (state, action) => {
  return updateObject(state, {
    paginationHeaders: null,
    publisherMedia: null,
    error: false,
    errorMessage: ""
  });
};

const showPublisherDetailResult = (state, action) => {
  return updateObject(state, {
    showResults: true
  });
};

const hidePublisherDetailResult = (state, action) => {
  return updateObject(state, {
    showResults: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PUBLISHERS:
      return setPublishers(state, action);
    case actionTypes.FETCH_PUBLISHERS_FAILED:
      return fetchPublishersFailed(state, action);
    case actionTypes.SET_PUBLISHER_DETAIL:
      return setPublisherDetail(state, action);
    case actionTypes.FETCH_PUBLISHER_DETAIL_FAILED:
      return fetchPublisherDetailFailed(state, action);
    case actionTypes.LEAVE_PUBLISHERS:
      return leavePublishers(state, action);
    case actionTypes.LEAVE_PUBLISHER_DETAIL:
      return leavePublisherDetail(state, action);
    case actionTypes.SHOW_PUBLISHER_DETAIL_RESULT:
      return showPublisherDetailResult(state, action);
    case actionTypes.HIDE_PUBLISHER_DETAIL_RESULT:
      return hidePublisherDetailResult(state, action);
    default:
      return state;
  }
};

const mediaImageURL = mediaId => {
  return apiEndpoints.MEDIA_IMAGE_ENDPOINT.replace("{id}", mediaId);
};

export default reducer;
