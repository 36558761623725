import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import classes from "./Spinner.module.css";

const spinner = props => {
  const { isInline, surpressPadding, size } = props;
  let spinnerStyle = isInline
    ? [classes.Main, classes.Gray]
    : [classes.Main, classes.Orange];

  if (surpressPadding !== true) {
    spinnerStyle.push(classes.MainPadding);
  }

  return (
    <div className={spinnerStyle.join(" ")}>
      <FontAwesome name="circle-o-notch" size={size ? size : "4x"} spin />
    </div>
  );
};

spinner.propTypes = {
  isInline: PropTypes.bool,
  size: PropTypes.string,
  surpressPadding: PropTypes.bool
};

export default spinner;
