import * as actionTypes from "./actionTypes";

export const fetchPublishers = (searchKeyword, appLanguage) => {
  return {
    type: actionTypes.FETCH_PUBLISHERS,
    searchKeyword: searchKeyword,
    appLanguage: appLanguage
  };
};

export const fetchPublishersFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_PUBLISHERS_FAILED,
    errorMessage: errorMessage
  };
};

export const setPublishers = (publishers, paginationHeaders) => {
  return {
    type: actionTypes.SET_PUBLISHERS,
    publishers: publishers,
    paginationHeaders: paginationHeaders
  };
};

export const fetchPublisherDetail = (
  publisherId,
  searchKeyword,
  appLanguage,
  gridType
) => {
  return {
    type: actionTypes.FETCH_PUBLISHER_DETAIL,
    publisherId: publisherId,
    searchKeyword: searchKeyword,
    appLanguage: appLanguage,
    gridType: gridType
  };
};

export const fetchPublisherDetailFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_PUBLISHER_DETAIL_FAILED,
    errorMessage: errorMessage
  };
};

export const setPublisherDetail = (publisherMedia, paginationHeaders) => {
  return {
    type: actionTypes.SET_PUBLISHER_DETAIL,
    publisherMedia: publisherMedia,
    paginationHeaders: paginationHeaders
  };
};

export const leavePublishers = () => {
  return {
    type: actionTypes.LEAVE_PUBLISHERS
  };
};

export const leavePublisherDetail = () => {
  return {
    type: actionTypes.LEAVE_PUBLISHER_DETAIL
  };
};

export const showPublisherDetailResult = () => {
  return {
    type: actionTypes.SHOW_PUBLISHER_DETAIL_RESULT
  };
};

export const hidePublisherDetailResult = () => {
  return {
    type: actionTypes.HIDE_PUBLISHER_DETAIL_RESULT
  };
};
