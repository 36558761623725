import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import { Container, Row, Col } from "reactstrap";
import classes from "./PublishersFeatures.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookOpen,
  faLifeRing,
  faDownload,
  faEnvelope,
  faHandshake,
  faLink,
  faCloud,
  faLock,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

const PublishersFeatures = () => {
  return (
    <>
      <div>
        <Container style={{ width: "80%" }}>
          <Row>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faBookOpen}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column1.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column1.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column1.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column2.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column2.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column2.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faLifeRing}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column3.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column3.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column3.Text" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faDownload}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column4.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column4.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column4.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column5.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column5.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column5.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faHandshake}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column6.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column6.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column6.Text" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faLink}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column7.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column7.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column7.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faCloud}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column8.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column8.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column8.Text" />
              </div>
            </Col>
            <Col sm="12" lg="4" className={classes.VerlagColumn}>
              <h4 className={classes.Header}>
                <FontAwesomeIcon
                  icon={faLock}
                  size="3x"
                  className={classes.Icons}
                />
                <br />
                <br />
                <Translate id="Home.Verlag.Column9.Header.Line1" />
                <br />
                <Translate id="Home.Verlag.Column9.Header.Line2" />
              </h4>
              <div className={classes.VerlagText}>
                <Translate id="Home.Verlag.Column9.Text" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>&nbsp;</Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PublishersFeatures;
