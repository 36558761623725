import React, { Component } from "react";
import { connect } from "react-redux";
import { deLocale } from "../../assets/locale/de";
import { enLocale } from "../../assets/locale/en";

function escapeRegExp(str) {
  return str.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
}

class Translate extends Component {
  render() {
    const {
      appLanguage,
      id,
      count,
      itemId,
      itemName,
      tagName,
      value
    } = this.props;
    let message =
      appLanguage === "en"
        ? enLocale
            .filter(message => message.id.toLowerCase() === id.toLowerCase())
            .reduce((arr, el) => el, null)
        : deLocale
            .filter(message => message.id.toLowerCase() === id.toLowerCase())
            .reduce((arr, el) => el, null);

    let messageText = null;

    if ((count || count === 0) && message) {
      switch (count) {
        case 0:
          messageText = message.text_0;
          break;
        case 1:
          messageText = message.text_1;
          break;
        default:
          messageText = message.text.replace("%{count}", count);
      }
    } else if (message && message.text) {
      messageText = message.text;

      if (itemName) {
        messageText = messageText.replace(
          new RegExp(escapeRegExp("%{name}"), "g"),
          itemName
        );
      }
      if (itemId) {
        messageText = messageText.replace("%{id}", itemId);
      }
    }

    if (tagName === "option") {
      let optionText = messageText ? messageText : "";
      let optionValue = value ? value : optionText;
      return <option value={optionValue}>{optionText}</option>;
    } else {
      return messageText ? <span>{messageText}</span> : <span>{""}</span>;
    }
  }
}

const mapStateToProps = state => {
  return {
    appLanguage: state.auth.appLanguage
  };
};

export default connect(mapStateToProps)(Translate);
