import * as actionTypes from "./actionTypes";

export const enterJobList = gridType => {
  return {
    type: actionTypes.ENTER_JOB_LIST,
    gridType: gridType
  };
};

export const leaveJobList = () => {
  return {
    type: actionTypes.LEAVE_JOB_LIST
  };
};

export const searchJobs = (term, date) => {
  return {
    type: actionTypes.SEARCH_JOBS,
    term: term,
    date: date
  };
};

export const searchReceiverJobs = term => {
  return {
    type: actionTypes.SEARCH_RECEIVER_JOBS,
    term: term
  };
};

export const searchJobsFail = () => {
  return {
    type: actionTypes.SEARCH_JOBS_FAIL
  };
};

export const fetchJobs = gridType => {
  return {
    type: actionTypes.FETCH_JOBS,
    gridType: gridType
  };
};

export const fetchJobsByDate = (date, dateFilter, searchKeyword, gridType) => {
  return {
    type: actionTypes.FETCH_JOBS_BY_DATE,
    date: date,
    dateFilter: dateFilter,
    term: searchKeyword,
    gridType: gridType
  };
};

export const fetchReceiverJobs = gridType => {
  return {
    type: actionTypes.FETCH_RECEIVER_JOBS,
    gridType: gridType
  };
};

export const fetchReceiverJobsByDate = (
  date,
  dateFilter,
  searchKeyword,
  gridType
) => {
  return {
    type: actionTypes.FETCH_RECEIVER_JOBS_BY_DATE,
    date: date,
    dateFilter: dateFilter,
    term: searchKeyword,
    gridType: gridType
  };
};

export const setJobs = (jobs, gridType) => {
  return {
    type: actionTypes.SET_JOBS,
    jobItems: jobs,
    gridType: gridType
  };
};

export const setJobsByDate = (jobs, dateFilter, gridType) => {
  return {
    type: actionTypes.SET_JOBS_BY_DATE,
    jobItems: jobs,
    dateFilter: dateFilter,
    gridType: gridType
  };
};

export const setReceiverJobsByDate = (jobs, dateFilter, gridType) => {
  return {
    type: actionTypes.SET_RECEIVER_JOBS_BY_DATE,
    jobItems: jobs,
    dateFilter: dateFilter,
    gridType: gridType
  };
};

export const fetchJobsFailed = () => {
  return {
    type: actionTypes.FETCH_JOBS_FAIL
  };
};

export const fetchJobsByDateFailed = () => {
  return {
    type: actionTypes.FETCH_JOBS_BY_DATE_FAIL
  };
};

export const updateJobs = updatedJobs => {
  return {
    type: actionTypes.UPDATE_JOBS,
    jobItems: updatedJobs
  };
};

export const filterByStatus = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_STATUS,
    jobItems: filteredJobs,
    statusFilter: filter,
    gridType: gridType
  };
};

export const filterByStatusSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_STATUS_SUCCESS,
    jobItems: filteredJobs,
    statusFilter: filter
  };
};

export const filterByStatusFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_STATUS_FAIL,
    jobItems: filteredJobs,
    statusFilter: filter
  };
};

export const filterByPublicationStatus = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS,
    jobItems: filteredJobs,
    publishedStatusFilter: filter,
    gridType: gridType
  };
};

export const filterByPublicationStatusSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS_SUCCESS,
    jobItems: filteredJobs,
    publishedStatusFilter: filter
  };
};

export const filterByPublicationStatusFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PUBLICATION_STATUS_FAIL,
    jobItems: filteredJobs,
    publishedStatusFilter: filter
  };
};

export const filterByJobExchange = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE,
    jobItems: filteredJobs,
    jobExchangeFilter: filter,
    gridType: gridType
  };
};

export const filterByJobExchangeSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE_SUCCESS,
    jobItems: filteredJobs,
    jobExchangeFilter: filter
  };
};

export const filterByJobExchangeFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_JOB_EXCHANGE_FAIL,
    jobItems: filteredJobs,
    jobExchangeFilter: filter
  };
};

export const filterByWord = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_WORD,
    jobItems: filteredJobs,
    wordTagFilter: filter,
    gridType: gridType
  };
};

export const filterByWordSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_WORD_SUCCESS,
    jobItems: filteredJobs,
    wordTagFilter: filter
  };
};

export const filterByWordFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_WORD_FAIL,
    jobItems: filteredJobs,
    wordTagFilter: filter
  };
};

export const filterByColor = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_COLOR,
    jobItems: filteredJobs,
    colorTagFilter: filter,
    gridType: gridType
  };
};

export const filterByColorSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_COLOR_SUCCESS,
    jobItems: filteredJobs,
    colorTagFilter: filter
  };
};

export const filterByColorFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_COLOR_FAIL,
    jobItems: filteredJobs,
    colorTagFilter: filter
  };
};

export const filterByPreflightStatus = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS,
    jobItems: filteredJobs,
    preflightStatusFilter: filter,
    gridType: gridType
  };
};

export const filterByPreflightSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS_SUCCESS,
    jobItems: filteredJobs,
    preflightStatusFilter: filter
  };
};

export const filterByPreflightFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_JOBS_BY_PREFLIGHT_STATUS_FAIL,
    jobItems: filteredJobs,
    preflightStatusFilter: filter
  };
};

export const approveJob = value => {
  return {
    type: actionTypes.APPROVE_JOB,
    value: value
  };
};

export const downloadJob = (jobId, appLanguage) => {
  return {
    type: actionTypes.DOWNLOAD_JOB,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const downloadJobFile = (jobId, appLanguage) => {
  return {
    type: actionTypes.DOWNLOAD_JOB_FILE,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const downloadJobFileFail = () => {
  return {
    type: actionTypes.DOWNLOAD_JOB_FILE_FAIL
  };
};

export const setJobAsReceived = jobId => {
  return {
    type: actionTypes.SET_JOB_AS_RECEIVED,
    jobId: jobId
  };
};

export const setJobAsReceivedFailed = jobId => {
  return {
    type: actionTypes.SET_JOB_AS_RECEIVED_FAIL,
    jobId: jobId
  };
};

export const downloadReportByType = (jobId, type) => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_BY_TYPE,
    jobId: jobId,
    reportType: type
  };
};

export const openReportHTML = (jobId, appLanguage) => {
  return {
    type: actionTypes.OPEN_REPORT_HTML,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const downloadReportBlob = (jobId, appLanguage) => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_BLOB,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const downloadReportFileFail = fileType => {
  return {
    type: actionTypes.DOWNLOAD_REPORT_FILE_FAIL,
    fileType: fileType
  };
};

export const sendJobFailed = () => {
  return {
    type: actionTypes.SEND_JOB_FAIL
  };
};

export const tagJob = (id, word, color, bookingInfo, workflowInfo) => {
  return {
    type: actionTypes.TAG_JOB,
    id: id,
    word: word,
    color: color,
    bookingInfo,
    workflowInfo
  };
};

export const tagJobFail = () => {
  return {
    type: actionTypes.TAG_JOB_FAIL
  };
};

export const saveWordTags = tags => {
  return {
    type: actionTypes.SAVE_WORD_TAGS,
    tags: tags
  };
};

export const saveWordTagsSuccess = tags => {
  return {
    type: actionTypes.SAVE_WORD_TAGS_SUCCESS,
    wordTags: JSON.parse(tags)
  };
};

export const saveWordTagsFail = () => {
  return {
    type: actionTypes.SAVE_WORD_TAGS_FAIL
  };
};

export const saveColorTags = tags => {
  return {
    type: actionTypes.SAVE_COLOR_TAGS,
    tags: tags
  };
};

export const saveColorTagsSuccess = tags => {
  return {
    type: actionTypes.SAVE_COLOR_TAGS_SUCCESS,
    colorTags: JSON.parse(tags)
  };
};

export const saveColorTagsFail = () => {
  return {
    type: actionTypes.SAVE_COLOR_TAGS_FAIL
  };
};

export const saveGridSettings = settings => {
  return {
    type: actionTypes.SAVE_GRID_SETTINGS,
    settings: settings
  };
};

export const saveGridSettingsSuccess = settings => {
  return {
    type: actionTypes.SAVE_GRID_SETTINGS_SUCCESS,
    gridSettings: JSON.parse(settings)
  };
};

export const saveGridSettingsFail = () => {
  return {
    type: actionTypes.SAVE_GRID_SETTINGS_FAIL
  };
};

export const saveReceiverGridSettings = settings => {
  return {
    type: actionTypes.SAVE_RECEIVER_GRID_SETTINGS,
    settings: settings
  };
};

export const saveReceiverGridSettingsSuccess = settings => {
  return {
    type: actionTypes.SAVE_RECEIVER_GRID_SETTINGS_SUCCESS,
    gridSettings: JSON.parse(settings)
  };
};

export const saveReceiverGridSettingsFail = () => {
  return {
    type: actionTypes.SAVE_RECEIVER_GRID_SETTINGS_FAIL
  };
};

export const setSearchTerm = term => {
  return {
    type: actionTypes.SET_SEARCH_TERM,
    searchKeyword: term
  };
};

export const fetchUserPreferences = gridType => {
  return {
    type: actionTypes.FETCH_USER_PREFERENCES,
    gridType: gridType
  };
};

export const fetchJobsByPreferences = (data, gridType) => {
  let dateTag = data.daten
    .filter(item => item.schluessel === "joblist.ui.dateTag")
    .reduce((arr, el) => el, []);

  return {
    type: actionTypes.SET_USER_PREFERENCES_AND_FETCH_JOBS,
    data: data,
    gridType: gridType,
    dateTag:
      dateTag && dateTag.constructor !== Array
        ? JSON.parse(dateTag.datenAlsJson.toString())
        : null
  };
};

export const fetchReceiverJobsByPreferences = (data, gridType) => {
  let dateTag = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.dateTag")
    .reduce((arr, el) => el, []);

  return {
    type: actionTypes.SET_RECEIVER_USER_PREFERENCES_AND_FETCH_JOBS,
    data: data,
    dateTag:
      dateTag && dateTag.constructor !== Array
        ? JSON.parse(dateTag.datenAlsJson.toString())
        : null,
    gridType: gridType
  };
};

export const setUserPreferences = data => {
  let wordTags = data.daten
    .filter(item => item.schluessel === "joblist.ui.wordtags")
    .reduce((arr, el) => el, []);

  let colorTags = data.daten
    .filter(item => item.schluessel === "joblist.ui.colortags")
    .reduce((arr, el) => el, []);

  let gridSettings = data.daten
    .filter(item => item.schluessel === "joblist.ui.gridsettings")
    .reduce((arr, el) => el, []);

  let senderGridGrouping = data.daten
    .filter(item => item.schluessel === "joblist.ui.grouping")
    .reduce((arr, el) => el, []);

  let senderGridSorting = data.daten
    .filter(item => item.schluessel === "joblist.ui.sorting")
    .reduce((arr, el) => el, []);

  let senderDateTag = data.daten
    .filter(item => item.schluessel === "joblist.ui.dateTag")
    .reduce((arr, el) => el, []);

  let statusFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.status")
    .reduce((arr, el) => el, []);

  let mediumFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.medium")
    .reduce((arr, el) => el, []);

  let issueFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.issue")
    .reduce((arr, el) => el, []);

  let preflightStatusFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.preflight")
    .reduce((arr, el) => el, []);

  let wordTagFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.word")
    .reduce((arr, el) => el, []);

  let colorTagFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.color")
    .reduce((arr, el) => el, []);

  let jobExchangeFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.jobexchange")
    .reduce((arr, el) => el, []);

  let publishedStatusFilter = data.daten
    .filter(item => item.schluessel === "joblist.ui.filters.publication")
    .reduce((arr, el) => el, []);

  let savedView = data.daten
    .filter(item => item.schluessel === "joblist.ui.view")
    .reduce((arr, el) => el, []);

  return {
    type: actionTypes.SET_USER_PREFERENCES,
    wordTags:
      wordTags && wordTags.constructor !== Array
        ? JSON.parse(wordTags.datenAlsJson)
        : null,
    colorTags:
      colorTags && colorTags.constructor !== Array
        ? JSON.parse(colorTags.datenAlsJson)
        : null,
    gridSettings:
      gridSettings && gridSettings.constructor !== Array
        ? JSON.parse(gridSettings.datenAlsJson).columns
        : null,
    senderGridGrouping:
      senderGridGrouping && senderGridGrouping.constructor !== Array
        ? JSON.parse(senderGridGrouping.datenAlsJson).grouping
        : null,
    senderGridSorting:
      senderGridSorting && senderGridSorting.constructor !== Array
        ? JSON.parse(senderGridSorting.datenAlsJson).sorting
        : null,
    senderDateTag:
      senderDateTag && senderDateTag.constructor !== Array
        ? JSON.parse(senderDateTag.datenAlsJson.toString()).toString()
        : null,
    statusFilter:
      statusFilter && statusFilter.constructor !== Array
        ? statusFilter.datenAlsJson.toString()
        : null,
    mediumFilter:
      mediumFilter && mediumFilter.constructor !== Array
        ? mediumFilter.datenAlsJson.toString()
        : null,
    issueFilter:
      issueFilter && issueFilter.constructor !== Array
        ? issueFilter.datenAlsJson.toString()
        : null,
    preflightStatusFilter:
      preflightStatusFilter && preflightStatusFilter.constructor !== Array
        ? preflightStatusFilter.datenAlsJson.toString()
        : null,
    wordTagFilter:
      wordTagFilter && wordTagFilter.constructor !== Array
        ? wordTagFilter.datenAlsJson.toString()
        : null,
    colorTagFilter:
      colorTagFilter && colorTagFilter.constructor !== Array
        ? colorTagFilter.datenAlsJson.toString()
        : null,
    savedView:
      savedView && savedView.constructor !== Array
        ? savedView.datenAlsJson.toString()
        : null,
    jobExchangeFilter:
      jobExchangeFilter && jobExchangeFilter.constructor !== Array
        ? jobExchangeFilter.datenAlsJson.toString()
        : null,
    publishedStatusFilter:
      publishedStatusFilter && publishedStatusFilter.constructor !== Array
        ? publishedStatusFilter.datenAlsJson.toString()
        : null
  };
};

export const fetchReceiverUserPreferences = gridType => {
  return {
    type: actionTypes.FETCH_RECEIVER_USER_PREFERENCES,
    gridType: gridType
  };
};

export const setReceiverUserPreferences = data => {
  let wordTags = data.daten
    .filter(item => item.schluessel === "joblist.ui.wordtags")
    .reduce((arr, el) => el, []);

  let colorTags = data.daten
    .filter(item => item.schluessel === "joblist.ui.colortags")
    .reduce((arr, el) => el, []);

  let gridSettings = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.gridsettings")
    .reduce((arr, el) => el, []);

  let receiverGridGrouping = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.grouping")
    .reduce((arr, el) => el, []);

  let receiverGridSorting = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.sorting")
    .reduce((arr, el) => el, []);

  let receiverDateTag = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.dateTag")
    .reduce((arr, el) => el, []);

  let statusFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.status")
    .reduce((arr, el) => el, []);

  let mediumFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.medium")
    .reduce((arr, el) => el, []);

  let issueFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.issue")
    .reduce((arr, el) => el, []);

  let preflightStatusFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.preflight")
    .reduce((arr, el) => el, []);

  let wordTagFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.word")
    .reduce((arr, el) => el, []);

  let colorTagFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.color")
    .reduce((arr, el) => el, []);

  let savedView = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.view")
    .reduce((arr, el) => el, []);

  let jobExchangeFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.jobexchange")
    .reduce((arr, el) => el, []);

  let publishedStatusFilter = data.daten
    .filter(item => item.schluessel === "receiverjobs.ui.filters.publication")
    .reduce((arr, el) => el, []);

  return {
    type: actionTypes.SET_RECEIVER_USER_PREFERENCES,
    wordTags:
      wordTags && wordTags.constructor !== Array
        ? JSON.parse(wordTags.datenAlsJson)
        : null,
    colorTags:
      colorTags && colorTags.constructor !== Array
        ? JSON.parse(colorTags.datenAlsJson)
        : null,
    gridReceiverSettings:
      gridSettings && gridSettings.constructor !== Array
        ? JSON.parse(gridSettings.datenAlsJson).columns
        : null,
    receiverGridGrouping:
      receiverGridGrouping && receiverGridGrouping.constructor !== Array
        ? JSON.parse(receiverGridGrouping.datenAlsJson).grouping
        : null,
    receiverGridSorting:
      receiverGridSorting && receiverGridSorting.constructor !== Array
        ? JSON.parse(receiverGridSorting.datenAlsJson).sorting
        : null,
    receiverDateTag:
      receiverDateTag && receiverDateTag.constructor !== Array
        ? JSON.parse(receiverDateTag.datenAlsJson.toString()).toString()
        : null,
    statusFilter:
      statusFilter && statusFilter.constructor !== Array
        ? statusFilter.datenAlsJson.toString()
        : null,
    mediumFilter:
      mediumFilter && mediumFilter.constructor !== Array
        ? mediumFilter.datenAlsJson.toString()
        : null,
    issueFilter:
      issueFilter && issueFilter.constructor !== Array
        ? issueFilter.datenAlsJson.toString()
        : null,
    preflightStatusFilter:
      preflightStatusFilter && preflightStatusFilter.constructor !== Array
        ? preflightStatusFilter.datenAlsJson.toString()
        : null,
    wordTagFilter:
      wordTagFilter && wordTagFilter.constructor !== Array
        ? wordTagFilter.datenAlsJson.toString()
        : null,
    colorTagFilter:
      colorTagFilter && colorTagFilter.constructor !== Array
        ? colorTagFilter.datenAlsJson.toString()
        : null,
    savedView:
      savedView && savedView.constructor !== Array
        ? savedView.datenAlsJson.toString()
        : null,
    jobExchangeFilter:
      jobExchangeFilter && jobExchangeFilter.constructor !== Array
        ? jobExchangeFilter.datenAlsJson.toString()
        : null,
    publishedStatusFilter:
      publishedStatusFilter && publishedStatusFilter.constructor !== Array
        ? publishedStatusFilter.datenAlsJson.toString()
        : null
  };
};

export const fetchUserPreferencesFail = () => {
  return {
    type: actionTypes.FETCH_USER_PREFERENCES_FAIL
  };
};

export const deleteJob = (containerId, jobId, canContainerBeDeleted) => {
  return {
    type: actionTypes.DELETE_JOB,
    containerId: containerId,
    jobId: jobId,
    canContainerBeDeleted: canContainerBeDeleted
  };
};

export const deleteJobFail = () => {
  return {
    type: actionTypes.DELETE_JOB_FAIL
  };
};

export const deleteContainer = containerId => {
  return {
    type: actionTypes.DELETE_CONTAINER,
    containerId: containerId
  };
};

export const deleteContainerFail = () => {
  return {
    type: actionTypes.DELETE_CONTAINER_FAIL
  };
};

export const sendContainerJobList = containerId => {
  return {
    type: actionTypes.SEND_CONTAINER_JOB_LIST,
    containerId: containerId
  };
};

export const sendContainerJobListFailed = () => {
  return {
    type: actionTypes.SEND_CONTAINER_JOB_LIST_FAIL
  };
};

export const startSendingContainer = () => {
  return {
    type: actionTypes.START_SENDING_CONTAINER
  };
};

export const finishSendingContainer = () => {
  return {
    type: actionTypes.FINISH_SENDING_CONTAINER
  };
};

export const checkContainerStatus = (containerId, retries, maxRetries) => {
  return {
    type: actionTypes.CHECK_CONTAINER_STATUS,
    containerId: containerId,
    retries: retries,
    maxRetries: maxRetries
  };
};

export const hideContainerSentSuccessMessage = () => {
  return {
    type: actionTypes.HIDE_CONTAINER_SENT_SUCCESS_MESSAGE
  };
};

export const saveSenderGrouping = grouping => {
  return {
    type: actionTypes.SAVE_SENDER_GROUPING,
    grouping: grouping
  };
};

export const saveSenderGroupingSuccess = grouping => {
  return {
    type: actionTypes.SAVE_SENDER_GROUPING_SUCCESS,
    grouping: grouping
  };
};

export const saveSenderGroupingFail = () => {
  return {
    type: actionTypes.SAVE_SENDER_GROUPING_FAIL
  };
};

export const saveReceiverGrouping = grouping => {
  return {
    type: actionTypes.SAVE_RECEIVER_GROUPING,
    grouping: grouping
  };
};

export const saveReceiverGroupingSuccess = grouping => {
  return {
    type: actionTypes.SAVE_RECEIVER_GROUPING_SUCCESS,
    grouping: grouping
  };
};

export const saveReceiverGroupingFail = () => {
  return {
    type: actionTypes.SAVE_RECEIVER_GROUPING_FAIL
  };
};

export const saveSenderSorting = sorting => {
  return {
    type: actionTypes.SAVE_SENDER_SORTING,
    sorting: sorting
  };
};

export const saveSenderSortingSuccess = sorting => {
  return {
    type: actionTypes.SAVE_SENDER_SORTING_SUCCESS,
    sorting: sorting
  };
};

export const saveSenderSortingFail = () => {
  return {
    type: actionTypes.SAVE_SENDER_SORTING_FAIL
  };
};

export const saveReceiverSorting = sorting => {
  return {
    type: actionTypes.SAVE_RECEIVER_SORTING,
    sorting: sorting
  };
};

export const saveReceiverSortingSuccess = sorting => {
  return {
    type: actionTypes.SAVE_RECEIVER_SORTING_SUCCESS,
    sorting: sorting
  };
};

export const saveReceiverSortingFail = () => {
  return {
    type: actionTypes.SAVE_RECEIVER_SORTING_FAIL
  };
};

export const resetJobList = () => {
  return {
    type: actionTypes.RESET_JOB_LIST
  };
};

export const resetReceiverJobList = () => {
  return {
    type: actionTypes.RESET_RECEIVER_JOB_LIST
  };
};

export const fetchContainerNotes = (containerId, jobId) => {
  return {
    type: actionTypes.FETCH_CONTAINER_JOB_NOTES,
    containerId: containerId,
    jobId: jobId
  };
};

export const fetchContainerNotesFailed = containerId => {
  return {
    type: actionTypes.FETCH_CONTAINER_JOB_NOTES_FAIL,
    containerId: containerId
  };
};

export const setJobListNotes = (jobId, containerNote, jobNote) => {
  return {
    type: actionTypes.SET_JOBLIST_NOTES,
    jobId: jobId,
    containerNote: containerNote,
    jobNote: jobNote
  };
};

export const copyJob = (jobId, copyWithPDF) => {
  return {
    type: actionTypes.COPY_JOB,
    jobId: jobId,
    copyWithPDF: copyWithPDF
  };
};

export const copyJobFailed = jobId => {
  return {
    type: actionTypes.COPY_JOB_FAIL,
    jobId: jobId
  };
};

export const copyContainer = (containerId, copyWithPDF) => {
  return {
    type: actionTypes.COPY_CONTAINER,
    containerId: containerId,
    copyWithPDF: copyWithPDF
  };
};

export const copyContainerFailed = containerId => {
  return {
    type: actionTypes.COPY_CONTAINER_FAIL,
    containerId: containerId
  };
};

export const exchangeJob = (jobId, exchangeWithPDF, appLanguage) => {
  return {
    type: actionTypes.EXCHANGE_JOB,
    jobId: jobId,
    copyWithPDF: exchangeWithPDF,
    appLanguage: appLanguage
  };
};

export const exchangeJobFailed = jobId => {
  return {
    type: actionTypes.EXCHANGE_JOB_FAIL,
    jobId: jobId
  };
};

export const setCopyContainerSuccess = (
  containerId,
  jobId,
  originalCopiedId,
  copyType
) => {
  return {
    type: actionTypes.SET_COPY_CONTAINER_SUCCESS,
    containerId: containerId,
    jobId: jobId,
    originalCopiedId: originalCopiedId,
    copyType: copyType
  };
};

export const setExchangeJobSuccess = (newJobId, oldJobId) => {
  return {
    type: actionTypes.SET_EXCHANGE_JOB_SUCCESS,
    newJobId: newJobId,
    oldJobId: oldJobId
  };
};

export const startCopyingContainer = () => {
  return {
    type: actionTypes.START_COPYING_CONTAINER
  };
};

export const finishCopyingContainer = () => {
  return {
    type: actionTypes.FINISH_COPYING_CONTAINER
  };
};

export const filterByMedium = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_BY_MEDIUM,
    jobItems: filteredJobs,
    mediumFilter: filter,
    gridType: gridType
  };
};

export const filterByMediumSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_BY_MEDIUM_SUCCESS,
    jobItems: filteredJobs,
    mediumFilter: filter
  };
};

export const filterByMediumFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_BY_MEDIUM_FAIL,
    jobItems: filteredJobs,
    mediumFilter: filter
  };
};

export const filterByIssue = (filteredJobs, filter, gridType) => {
  return {
    type: actionTypes.FILTER_BY_ISSUE,
    jobItems: filteredJobs,
    issueFilter: filter,
    gridType: gridType
  };
};

export const filterByIssueSuccess = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_BY_ISSUE_SUCCESS,
    jobItems: filteredJobs,
    issueFilter: filter
  };
};

export const filterByIssueFail = (filteredJobs, filter) => {
  return {
    type: actionTypes.FILTER_BY_ISSUE_FAIL,
    jobItems: filteredJobs,
    issueFilter: filter
  };
};

export const resetFilters = () => {
  return {
    type: actionTypes.RESET_JOBLIST_FILTERS
  };
};

export const saveSenderDateTag = dateTag => {
  return {
    type: actionTypes.SAVE_SENDER_DATETAG,
    dateTag: dateTag
  };
};

export const saveSenderDateTagSuccess = dateTag => {
  return {
    type: actionTypes.SAVE_SENDER_DATETAG_SUCCESS,
    dateTag: dateTag
  };
};

export const saveSenderDateTagFail = () => {
  return {
    type: actionTypes.SAVE_SENDER_DATETAG_FAIL
  };
};

export const saveReceiverDateTag = dateTag => {
  return {
    type: actionTypes.SAVE_RECEIVER_DATETAG,
    dateTag: dateTag
  };
};

export const saveReceiverDateTagSuccess = dateTag => {
  return {
    type: actionTypes.SAVE_RECEIVER_DATETAG_SUCCESS,
    dateTag: dateTag
  };
};

export const saveReceiverDateTagFail = () => {
  return {
    type: actionTypes.SAVE_RECEIVER_DATETAG_FAIL
  };
};

export const saveView = (value, gridType) => {
  return {
    type: actionTypes.SAVE_VIEW,
    value: value,
    gridType: gridType
  };
};

export const saveViewSuccess = value => {
  return {
    type: actionTypes.SAVE_VIEW_SUCCESS,
    value: value
  };
};

export const saveViewFail = () => {
  return {
    type: actionTypes.SAVE_VIEW_FAIL
  };
};

export const changeSenderGridSorting = value => {
  return {
    type: actionTypes.CHANGE_SENDER_GRID_SORTING,
    sorting: value
  };
};

export const changeReceiverGridSorting = value => {
  return {
    type: actionTypes.CHANGE_RECEIVER_GRID_SORTING,
    sorting: value
  };
};

export const changeSenderGridGrouping = value => {
  return {
    type: actionTypes.CHANGE_SENDER_GRID_GROUPING,
    grouping: value
  };
};

export const changeReceiverGridGrouping = value => {
  return {
    type: actionTypes.CHANGE_RECEIVER_GRID_GROUPING,
    grouping: value
  };
};
