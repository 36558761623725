import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { translateAsLiteral } from "../../../helpers/utility";
import classes from "./Paperplane.module.css";

const paperplane = props => {
  const { issueStatus, type, appLanguage } = props;

  let style = null;
  let reusltText = null;

  switch (issueStatus) {
    case "grey":
      style = [classes.Main, classes.Grey];
      reusltText = translateAsLiteral(
        appLanguage,
        "JobList.Preflight.NotProofed"
      );
      break;
    case "red":
      style = [classes.Main, classes.Red];
      reusltText = translateAsLiteral(appLanguage, "JobList.Preflight.Error");
      break;
    case "orange":
      style = [classes.Main, classes.Orange];
      reusltText = translateAsLiteral(appLanguage, "JobList.Preflight.Warning");
      break;
    default:
      style = [classes.Main, classes.Green];
      reusltText = "OK";
  }

  return type === "square" ? (
    <div className={[...style, classes.MainFlex].join(" ")}>
      <FontAwesome name="paper-plane" />
      <div className={classes.Text}>{reusltText}</div>
    </div>
  ) : (
    <div className={style.join(" ") + " text-white mx-auto"}>
      <FontAwesome name="paper-plane" />
    </div>
  );
};

paperplane.propTypes = {
  type: PropTypes.string.isRequired,
  issueStatus: PropTypes.string,
  appLanguage: PropTypes.string
};

export default paperplane;
