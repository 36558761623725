const jobData = {
  partialBooking: {
    value: false,
    valid: true,
    touched: false,
    validation: {
      required: false
    }
  },
  motive: {
    value: "",
    valid: null,
    touched: false,
    validation: {
      required: true,
      maxLength: 255
    }
  },
  notes: {
    value: "",
    valid: true,
    touched: false,
    validation: {
      required: false,
      maxLength: 1000
    }
  }
};

export default jobData;
