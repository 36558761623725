import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import classes from "./Dropdown.module.css";

const dropdown = props => {
  const {
    show,
    hasChildren,
    text,
    disabled,
    id,
    children,
    showRequiredBorder,
    toggleList
  } = props;

  let dropDownStyle = [
    showRequiredBorder ? classes.MainRequired : classes.Main,
    show ? classes.Show : classes.Hide
  ];

  let displayText = null;

  if (typeof text === "string") {
    displayText = (
      <div
        className={
          disabled ? [classes.Text, classes.Disabled].join(" ") : classes.Text
        }
      >
        <span>{text}</span>
      </div>
    );
  } else {
    displayText = <div className={classes.Label}>{text}</div>;
  }

  return (
    <div id={id} className={dropDownStyle.join(" ")}>
      <div className={classes.Display} onClick={disabled ? null : toggleList}>
        {displayText}
        {hasChildren && (
          <div className={disabled ? classes.CaretDisabled : classes.Caret}>
            <FontAwesome
              name="angle-down"
              size="2x"
              className={classes.CaretArrow}
            />
          </div>
        )}
      </div>
      {children}
    </div>
  );
};

dropdown.propTypes = {
  id: PropTypes.string,
  show: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasChildren: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  showRequiredBorder: PropTypes.bool,
  toggleList: PropTypes.func
};

export default dropdown;
