import * as actionTypes from "./actionTypes";

export const setMediaItems = (mediaItems, paginationHeaders) => {
  return {
    type: actionTypes.SET_MEDIA_ITEMS,
    mediaItems: mediaItems,
    paginationHeaders: paginationHeaders
  };
};

export const setMediaItemNames = mediaItems => {
  return {
    type: actionTypes.SET_MEDIA_ITEM_NAMES,
    mediaItems: mediaItems
  };
};

export const fetchMediaItemsFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_MEDIA_ITEMS_FAILED,
    errorMessage: errorMessage
  };
};

export const fetchMediaItems = (searchKeyword, isCompact, appLanguage) => {
  return {
    type: actionTypes.FETCH_MEDIA_ITEMS,
    searchKeyword: searchKeyword,
    isCompact: isCompact,
    appLanguage: appLanguage
  };
};

export const fetchMediaAzureItems = (searchKeyword, isCompact, appLanguage) => {
  return {
    type: actionTypes.FETCH_MEDIA_AZURE_ITEMS,
    searchKeyword: searchKeyword,
    isCompact: isCompact,
    appLanguage: appLanguage
  };
};

export const fetchMediaItemNames = () => {
  return {
    type: actionTypes.FETCH_MEDIA_ITEM_NAMES
  };
};

export const setIssues = (issues, mediaItemId) => {
  return {
    type: actionTypes.SET_ISSUES,
    issues: issues,
    mediaItemId: mediaItemId
  };
};

export const fetchIssuesFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_ISSUES_FAILED,
    errorMessage: errorMessage
  };
};

export const fetchIssues = mediaItemId => {
  return {
    type: actionTypes.FETCH_ISSUES,
    mediaItemId: mediaItemId
  };
};

export const fetchDates = mediaItemId => {
  return {
    type: actionTypes.FETCH_DATES,
    mediaItemId: mediaItemId
  };
};

export const fetchCertifiedItems = () => {
  return {
    type: actionTypes.FETCH_DUON_CERTIFIED
  };
};

export const leaveMediaItem = () => {
  return {
    type: actionTypes.LEAVE_MEDIA_ITEM
  };
};
