import React, { Component } from "react";
import classes from "./StickyAlert.module.css";

class StickyAlert extends Component {
  state = {
    isVisible: true
  };

  hideStickyAlert = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const { messageDE, messageEN, linkDE, linkEN } = this.props;
    const { isVisible } = this.state;

    return isVisible ? (
      <div id="hintBanner" className={classes.Main}>
        <div>
          &nbsp;&nbsp;{messageDE}{" "}
          {linkDE && (
            <a
              className={classes.Link}
              href={linkDE}
              target="_blank"
              rel="noopener noreferrer"
            >
              Erfahren Sie mehr…
            </a>
          )}
          &nbsp;&nbsp;|&nbsp; {messageEN}{" "}
          {linkEN && (
            <a
              className={classes.Link}
              href={linkEN}
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more …
            </a>
          )}
        </div>
        <div className={classes.CloseButton} onClick={this.hideStickyAlert}>
          x
        </div>
      </div>
    ) : null;
  }
}

export default StickyAlert;
