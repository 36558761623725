import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  containerId: null,
  jobId: null,
  operations: null,
  error: false,
  errorMessage: null
};

const setJobHistory = (state, action) => {
  let filteredOperations = action.operations.filter(
    item => item.aktionsId !== 1 && item.aktionsId !== 10
  );

  return updateObject(state, {
    operations: filteredOperations,
    containerId: action.containerId,
    jobId: action.jobId
  });
};

const fetchJobHistoryFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_JOB_HISTORY:
      return setJobHistory(state, action);
    case actionTypes.FETCH_JOB_HISTORY_FAILED:
      return fetchJobHistoryFailed(state, action);
    default:
      return state;
  }
};

export default reducer;
