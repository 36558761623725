import API from "../../api";
import PrivateAPI from "../../privateApi";
import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";
import { errorHandler } from "../../helpers/Logger/Logger";
import { JOB_HISTORY_ENDPOINT } from "../../helpers/apiEndpoints";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* fetchJobHistorySaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PrivateAPI.get(
      jobHistoryURL(action.containerId, action.jobId, action.appLanguage)
    );
    yield put(
      actions.setJobHistory(action.containerId, action.jobId, response.data)
    );
  } catch (error) {
    let errorMessage = "Job History can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = "Keine entsprechenden Historie gefunden.";
    }
    yield put(actions.fetchJobHistoryFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchJobHistory",
      jobHistoryURL(action.containerId, action.jobId, action.appLanguage)
    );
  }
}

const jobHistoryURL = (containerId, jobId, language) => {
  return JOB_HISTORY_ENDPOINT.replace("{id}", jobId).replace(
    "{sprache}",
    language
  );
};
