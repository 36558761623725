import API from "../../api";
import { put, call } from "redux-saga/effects";
import * as actions from "../actions/index";
import { errorHandler } from "../../helpers/Logger/Logger";
import {
  ALL_MEDIA_ENDPOINT,
  ALL_MEDIA_AZURE_ENDPOINT,
  ISSUES_ENDPOINT,
  DATES_ENDPOINT
} from "../../helpers/apiEndpoints";
import { translateAsLiteral } from "../../helpers/utility";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* fetchMediaItemsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      mediaURL(action.searchKeyword, action.isCompact)
    );

    let paginationHeaders = null;

    if (response.headers['x-pagination']) {
      paginationHeaders = JSON.parse(response.headers['x-pagination']);
    }
    yield put(actions.setMediaItems(response.data, paginationHeaders));
  } catch (error) {
    let errorMessage = "Media Items can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = translateAsLiteral(
        action.appLanguage,
        "Media.MediaNotFound"
      );
    }
    yield put(actions.fetchMediaItemsFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchMediaItems",
      mediaURL(action.searchKeyword, action.isCompact)
    );
  }
}

export function* fetchMediaAzureItemsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(
      mediaAzureURL(action.searchKeyword, action.isCompact)
    );
    let paginationHeaders = null;
    if (response.headers["x-pagination"]) {
      paginationHeaders = JSON.parse(response.headers["x-pagination"]);
    }
    yield put(actions.setMediaItems(response.data, paginationHeaders));
  } catch (error) {
    let errorMessage = "Media Items can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = translateAsLiteral(
        action.appLanguage,
        "Media.MediaNotFound"
      );
    }
    yield put(actions.fetchMediaItemsFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchMediaItems",
      mediaAzureURL(action.searchKeyword, action.isCompact)
    );
  }
}

export function* fetchMediaItemNamesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(mediaNamesURL(""));
    yield put(actions.setMediaItemNames(response.data));
  } catch (error) {
    let errorMessage = "Media Items can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = "Keine entsprechenden Medien gefunden.";
    }
    yield put(actions.fetchMediaItemsFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchMediaItemNames",
      mediaNamesURL("")
    );
  }
}

export function* fetchIssuesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(issuesURL(action.mediaItemId));
    yield put(actions.setIssues(response.data, action.mediaItemId));
  } catch (error) {
    let errorMessage =
      error.response.status === 404
        ? "Publication dates can't be loaded"
        : "Publication dates can't be loaded";
    yield put(actions.fetchIssuesFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchIssues",
      issuesURL(action.mediaItemId)
    );
  }
}

export function* fetchDatesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(datesURL(action.mediaItemId));
    yield put(actions.setIssues(response.data, action.mediaItemId));
  } catch (error) {
    let errorMessage =
      error.response.status === 404
        ? "Publication dates can't be loaded"
        : "Publication dates can't be loaded";
    yield put(actions.fetchIssuesFailed(errorMessage));
  }
}

const mediaURL = (params, isCompact) => {
  let url =
    params.length > 0
      ? `${ALL_MEDIA_ENDPOINT}?${params}&SeitenGroesse=150`
      : `${ALL_MEDIA_ENDPOINT}?Suchbegriff=*&SeitenGroesse=150`;
  if (isCompact) {
    url = url + "&SeitenGroesse=15";
  }
  return url;
};

const mediaAzureURL = (params, isCompact) => {
  let url =
    params.length > 0
      ? `${ALL_MEDIA_AZURE_ENDPOINT}?${params}`
      : `${ALL_MEDIA_AZURE_ENDPOINT}?Suchbegriff=*`;
  if (isCompact) {
    url = url + "&SeitenGroesse=15";
  }
  return url;
};

const mediaNamesURL = params => {
  let url =
    params.length > 0
      ? `${ALL_MEDIA_ENDPOINT}?${params}`
      : `${ALL_MEDIA_ENDPOINT}?Suchbegriff=*&SeitenGroesse=1000`;
  return url;
};

const issuesURL = mediaItemId => {
  return ISSUES_ENDPOINT.replace("{id}", mediaItemId);
};

const datesURL = mediaItemId => {
  return DATES_ENDPOINT.replace("{id}", mediaItemId);
};
