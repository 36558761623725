import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./Publishers.module.css";

const Publishers = () => {
  return (
    <>
      <div>
        <h3 className={classes.Header}>
          <Translate id="Home.SecondParagraph.Header" />
        </h3>
        <div>
          <Translate id="Home.SecondParagraph.FirstLine" />
        </div>
        <div>
          <Translate id="Home.SecondParagraph.SecondLine" />
        </div>
        <div>
          <Translate id="Home.SecondParagraph.ThirdLine" />
        </div>
        <div>
          <Translate id="Home.SecondParagraph.FourthLine" />
        </div>
      </div>
    </>
  );
};

export default Publishers;
