import React from "react";
import FontAwesome from "react-fontawesome";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./ContainerButton.module.css";

export const ContainerButton = props => {
  const { outline, disabled, onClick, size, block, icon, id } = props;
  const outlineClassName = outline === true ? classes.outline : "";
  let styleClass = disabled
    ? classes.Disabled
    : [classes.ContainerButton, outlineClassName].join(" ");

  return (
    <div>
      <Button
        id={id}
        outline={outline}
        disabled={disabled}
        className={styleClass}
        onClick={onClick}
        size={size}
        block={block}
      >
        {icon && <FontAwesome name={icon} size="2x" />}
      </Button>
    </div>
  );
};

ContainerButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  outline: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  id: PropTypes.string
};
