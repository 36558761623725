import React from "react";
import PropTypes from "prop-types";
import classes from "./DotDotDot.module.css";

const dotDotDot = props => {
  let classNames = [classes.Blink];
  if (props.className) {
    classNames.push(props.className);
  }
  return (
    <span className={classNames.join(" ")}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
};

dotDotDot.propTypes = {
  className: PropTypes.string
};

export default dotDotDot;
