import React from "react";
import PropTypes from "prop-types";
import { Button, Row, Col } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";

const CannotLogin = props => {
  const { toggleForgotPasswordForm, toggleConfirmEmailForm } = props;

  return (
    <div className="my-4">
      <hr />
      <Row>
        <Col>
          <Button outline color="primary" onClick={toggleForgotPasswordForm}>
            <Translate id="Auth.ForgotPassword" />
          </Button>
        </Col>
        <Col>
          <Button outline color="primary" onClick={toggleConfirmEmailForm}>
            <Translate id="Auth.Inactive" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};

CannotLogin.propTypes = {
  toggleForgotPasswordForm: PropTypes.func,
  toggleConfirmEmailForm: PropTypes.func
};

export default CannotLogin;
