import React from "react";
import FontAwesome from "react-fontawesome";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./Pricing.module.css";

const Pricing = () => {
  return (
    <>
      <div id="preise">
        <h3 className={classes.Header}>
          <FontAwesome name="tag" />
          &nbsp;&nbsp;
          <Translate id="Home.Pricing.Header" />
        </h3>
      </div>
    </>
  );
};

export default Pricing;
