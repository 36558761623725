import * as actionTypes from "./actionTypes";
import API from "../../api";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { groupBy } from "@progress/kendo-data-query";
import lodash from "lodash";

export const initMediaDetail = (mediaId, issuePublicationDate, appLanguage) => {
  return {
    type: actionTypes.INIT_MEDIA_DETAIL,
    mediaId: mediaId,
    issuePublicationDate: issuePublicationDate,
    appLanguage: appLanguage
  };
};

export const initTechnicalURL = (
  mediaId,
  issuePublicationDate,
  appLanguage,
  md5Hash,
  partialBooking
) => {
  return {
    type: actionTypes.INIT_TECHNICAL_URL,
    mediaId: mediaId,
    issuePublicationDate: issuePublicationDate,
    appLanguage: appLanguage,
    md5Hash: md5Hash,
    partialBooking: partialBooking
  };
};

export const fetchMediaDetail = mediaId => {
  return {
    type: actionTypes.FETCH_MEDIA_DETAIL,
    mediaId: mediaId
  };
};

export const fetchMediaDetailTechnicalInfo = (
  mediaId,
  selectedIssue,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_MEDIA_DETAIL_TECHNICAL_INFO,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    isJobCreationAllowed: selectedIssue.datum,
    appLanguage: appLanguage
  };
};

export const fetchCurrentMediaDetailTechnicalInfo = (
  mediaId,
  issuePublicationDate,
  appLanguage
) => {
  return {
    type: actionTypes.FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO,
    mediaId: mediaId,
    issuePublicationDate: issuePublicationDate,
    appLanguage: appLanguage
  };
};

export const fetchTechnicalURLInfo = (
  mediaId,
  issuePublicationDate,
  appLanguage,
  md5Hash,
  partialBooking
) => {
  return {
    type: actionTypes.FETCH_TECHNICAL_URL_INFO,
    mediaId: mediaId,
    issuePublicationDate: issuePublicationDate,
    appLanguage: appLanguage,
    md5Hash: md5Hash,
    partialBooking: partialBooking
  };
};

export const fetchTechnicalURLInfoFailed = () => {
  return {
    type: actionTypes.FETCH_TECHNICAL_URL_INFO_FAIL
  };
};

export const setMediaDetail = (mediaId, data) => {
  let imageUrl = API.defaults.baseURL + mediaImageURL(mediaId);
  return {
    type: actionTypes.SET_MEDIA_DETAIL,
    mediaItem: data,
    mediaImage: imageUrl
  };
};

export const setMediaDetailIssuesState = issues => {
  return {
    type: actionTypes.SET_MEDIA_DETAIL_ISSUES,
    issues: issues
  };
};

export const setMediaSelectedIssueState = selectedIssue => {
  return {
    type: actionTypes.SET_MEDIA_DETAIL_SELECTED_ISSUE,
    selectedIssue: selectedIssue
  };
};

// ToDo: Place logic in reducer?
export const setMediaDetailTechnicalInfo = (technicalInfo, appLanguage) => {
  let colourProfileMap = new Map();
  let formatMap = new Map();

  technicalInfo.platzierungenDruckverfahrenFarbprofile.forEach(fp => {
    colourProfileMap.set(fp.platzierungsId, {
      bezeichnung: fp.farbprofil.bezeichnung,
      uri: fp.farbprofil.profilPaketUri,
      subCategoryName: fp.platzierung
    });
  });

  technicalInfo.anzeigenFestFormate.forEach(item => {
    formatMap.set(item.mD5Hash, item);
  });

  for (let item of formatMap.values()) {
    item.categoryName =
      item.platzierungsId === 498
        ? item.formatPlatzierungsZusatz
        : item.platzierung;

    let colourProfile = colourProfileMap.get(
      item.farbprofilRelevantePlatzierungsId
    );

    if (colourProfile) {
      item.colourProfile = colourProfile.bezeichnung;
      item.colourProfileUri = colourProfile.uri;
      item.subCategoryName = colourProfile.subCategoryName;
      item.partialBooking = false;
    }

    if (!item.splitFormatLinksMD5Hash || !item.splitFormatRechtsMD5Hash) {
      continue;
    }

    item.splitFormatLeft = formatMap.get(item.splitFormatLinksMD5Hash);
    item.splitFormatRight = formatMap.get(item.splitFormatRechtsMD5Hash);
  }

  let formatMapTeilbelegung = new Map();
  if (technicalInfo.istTeilbelegungMoeglich) {
    formatMapTeilbelegung = lodash.cloneDeep(formatMap);

    for (let item of formatMapTeilbelegung.values()) {
      let colourProfile = colourProfileMap.get(463);
      if (colourProfile) {
        item.colourProfile = colourProfile.bezeichnung;
        item.colourProfileUri = colourProfile.uri;
        item.categoryName =
          item.categoryName + " - " + colourProfile.subCategoryName;
        item.subCategoryName = colourProfile.subCategoryName;
        item.partialBooking = true;
      }
    }
  } else {
  }

  const itemsSortedNoBleedToTop = Array.from(formatMap.values()).sort(
    (a, b) => {
      return b.anschnitt - a.anschnitt;
    }
  );

  const itemsSortedNoBleedToTopTeilbelegung = Array.from(
    formatMapTeilbelegung.values()
  ).filter(
    x =>
      (x.platzierungsId < 430 || x.platzierungsId > 433) &&
      x.platzierungsId !== 461
  );

  itemsSortedNoBleedToTopTeilbelegung.sort((a, b) => {
    return b.anschnitt - a.anschnitt;
  });

  let merged = [
    ...itemsSortedNoBleedToTop,
    ...itemsSortedNoBleedToTopTeilbelegung
  ];

  let groupedFormats = groupBy(merged, [
    { field: "categoryName", dir: "desc" }
  ]);

  return {
    type: actionTypes.SET_MEDIA_DETAIL_ISSUE,
    mediaBindingMethod: technicalInfo.verarbeitung.bezeichnung,
    formats: groupedFormats,
    bleedInfo: technicalInfo.beschnittZugaben,
    issueNumber: technicalInfo.heftNummer,
    validDataFormats: technicalInfo.gueltigeDateiformate,
    recommendedDataFormat: technicalInfo.empfohlenesDateiformat,
    printMaterialDeadline: technicalInfo.duSchluss,
    publicationDate: technicalInfo.erscheinungsTermin,
    sizes: technicalInfo.geometrie,
    bunddoppelungUmschlag: technicalInfo.bunddoppelungUmschlag,
    bunddoppelungInnenteil: technicalInfo.bunddoppelungInnenteil,
    istTeilbelegungMoeglich: technicalInfo.istTeilbelegungMoeglich
  };
};

export const setMediaDetailContactPersonState = contactPersons => {
  return {
    type: actionTypes.SET_MEDIA_DETAIL_CONTACT_PERSON,
    contactPersons: contactPersons
  };
};

export const fetchMediaDetailFailed = () => {
  return {
    type: actionTypes.FETCH_MEDIA_DETAIL_FAIL
  };
};

export const fetchMediaDetailTechnicalInfoFailed = () => {
  return {
    type: actionTypes.FETCH_MEDIA_DETAIL_TECHNICAL_INFO_FAIL
  };
};

export const fetchCurrentMediaDetailTechnicalInfoFailed = () => {
  return {
    type: actionTypes.FETCH_CURRENT_MEDIA_DETAIL_TECHNICAL_INFO_FAIL
  };
};

export const actualizeMediaDetailTechnicalInfo = (
  mediaId,
  selectedIssue,
  formatMD5Hash,
  appLanguage,
  partialBooking,
  parentFormatMD5Hash
) => {
  return {
    type: actionTypes.ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO,
    mediaId: mediaId,
    selectedIssue: selectedIssue,
    formatMD5Hash: formatMD5Hash,
    appLanguage: appLanguage,
    partialBooking: partialBooking,
    parentFormatMD5Hash: parentFormatMD5Hash
  };
};

export const actualizeMediaDetailTechnicalInfoFailed = () => {
  return {
    type: actionTypes.ACTUALIZE_MEDIA_DETAIL_TECHNICAL_INFO_FAIL
  };
};

export const setActualizedMediaDetailTechnicalInfo = technicalInfo => {
  return {
    type: actionTypes.SET_ACTUALIZED_MEDIA_DETAIL_TECHNICAL_INFO,
    technicalInfo: technicalInfo
  };
};

export const downloadTechnicalInfo = (
  mediumId,
  issueDate,
  issueNumber,
  appLanguage
) => {
  return {
    type: actionTypes.DOWNLOAD_TECHNICAL_INFO,
    mediumId: mediumId,
    issueDate: issueDate,
    issueNumber: issueNumber,
    appLanguage: appLanguage
  };
};

export const downloadTechnicalInfoFail = () => {
  return {
    type: actionTypes.DOWNLOAD_TECHNICAL_INFO_FAIL
  };
};

export const leaveMediaDetail = () => {
  return {
    type: actionTypes.LEAVE_MEDIA_DETAIL
  };
};

const mediaImageURL = mediaId => {
  return apiEndpoints.MEDIA_IMAGE_ENDPOINT.replace("{id}", mediaId);
};
