import React from "react";
import FontAwesome from "react-fontawesome";
import PropTypes from "prop-types";
import { FormGroup, Button } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./LoginHeader.module.css";

const LoginHeader = props => {
  const { redirectToRegister } = props;

  return (
    <div className={classes.Header}>
      <div className={classes.Header}>
        <FontAwesome name="lock" size="2x" />
        &nbsp;&nbsp;
        <h4>
          <Translate id="Auth.Login" />
        </h4>
      </div>
      <div>
        <FormGroup>
          <Button outline color="primary" onClick={redirectToRegister}>
            <Translate id="Auth.Register" />
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

LoginHeader.propTypes = {
  redirectToRegister: PropTypes.func
};

export default LoginHeader;
