import React from "react";
import PropTypes from "prop-types";
import { Button, Label } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./Actions.module.css";

export const Actions = props => {
  const {
    isAuthenticated,
    redirectToJobBuilder,
    appLanguage,
    redirectToLogin,
    redirectToRegistration
  } = props;

  return isAuthenticated ? (
    <div className={classes.RegisterSection}>
      <Button
        onClick={redirectToJobBuilder}
        className={classes.RegisterButton}
        outline
        color="secondary"
      >
        <Translate id="Navigation.CreateJob" />
      </Button>{" "}
      <Label className={classes.LoginHint}>
        {appLanguage === "en" ? "or" : "oder"}
      </Label>
    </div>
  ) : (
    <div className={classes.RegisterSection}>
      <Button
        onClick={redirectToRegistration}
        className={classes.RegisterButton}
        outline
        color="secondary"
      >
        <Translate id="Home.Buttons.Register" />
      </Button>{" "}
      <Label className={classes.LoginHint}>
        <Translate id="Home.Login.Hint" />
      </Label>
      <Button onClick={redirectToLogin} className={classes.LoginButton} outline>
        <Translate id="Navigation.Login" />
      </Button>
    </div>
  );
};

Actions.propTypes = {
  isAuthenticated: PropTypes.bool,
  redirectToJobBuilder: PropTypes.func,
  appLanguage: PropTypes.string,
  redirectToLogin: PropTypes.func,
  redirectToRegistration: PropTypes.func
};

export default Actions;
