import * as actionTypes from "./actionTypes";
import API from "../../api";
import * as apiEndpoints from "../../helpers/apiEndpoints";

export const setFreigabeMediaItems = (mediaItems, paginationHeaders) => {
  return {
    type: actionTypes.SET_FREIGABE_MEDIA_ITEMS,
    mediaItems: mediaItems,
    paginationHeaders: paginationHeaders
  };
};

export const fetchFreigabeMediaItemsFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_FREIGABE_MEDIA_ITEMS_FAILED,
    errorMessage: errorMessage
  };
};

export const fetchFreigabeMediaItems = searchKeyword => {
  return {
    type: actionTypes.FETCH_FREIGABE_MEDIA_ITEMS,
    searchKeyword: searchKeyword
  };
};

export const initFreigabeDetail = (mediumId, appLanguage) => {
  return {
    type: actionTypes.INIT_FREIGABE_DETAIL,
    mediumId: mediumId,
    appLanguage: appLanguage
  };
};

export const initFreigabeDetailFail = () => {
  return {
    type: actionTypes.INIT_FREIGABE_DETAIL_FAIL
  };
};

export const setFreigabeDates = (mediumId, data) => {
  return {
    type: actionTypes.SET_FREIGABE_DATES,
    mediumId: mediumId,
    dates: data
  };
};

export const fetchFreigabeDates = (mediumId, appLanguage) => {
  return {
    type: actionTypes.FETCH_FREIGABE_DATES,
    mediumId: mediumId,
    appLanguage: appLanguage
  };
};

export const fetchFreigabeMediaDetail = mediumId => {
  return {
    type: actionTypes.FETCH_FREIGABE_MEDIA_DETAIL,
    mediumId: mediumId
  };
};

export const setFreigabeMediaDetail = (mediumId, data) => {
  let imageUrl = API.defaults.baseURL + mediaImageURL(mediumId);
  return {
    type: actionTypes.SET_FREIGABE_MEDIA_DETAIL,
    mediaItem: data,
    mediaImage: imageUrl
  };
};

export const fetchFreigabeInfo = (mediumId, datum, appLanguage) => {
  return {
    type: actionTypes.FETCH_FREIGABE_INFO,
    mediumId: mediumId,
    datum: datum,
    appLanguage: appLanguage
  };
};

export const fetchFreigabeInfoFail = () => {
  return {
    type: actionTypes.FETCH_FREIGABE_INFO_FAIL
  };
};

export const setFreigabeInfo = (data, datum) => {
  return {
    type: actionTypes.SET_FREIGABE_INFO,
    contactPersons: data.ansprechpartner,
    issueWidth: data.geometrie.breite,
    issueHeight: data.geometrie.hoehe,
    issueMeasurement: data.geometrie.einheit,
    satzspiegelWidth: data.geometrie.satzspiegelBreite,
    satzspiegelHeight: data.geometrie.satzspiegelHoehe,
    beschnittZugaben: data.beschnittZugaben,
    issues: data.weitereTermine,
    formats: data.anzeigenFestFormate,
    placements: data.platzierungenDruckverfahrenFarbprofile,
    datum: datum,
    pdfTypes: data.gueltigeDateiformate
  };
};

export const certifyMedium = mediumId => {
  return {
    type: actionTypes.CERTIFY_MEDIUM,
    mediumId: mediumId
  };
};

export const setCertifiedMediumResult = status => {
  return {
    type: actionTypes.SET_CERTIFIED_MEDIUM_RESULT,
    status: status
  };
};

export const downloadFreigabeFile = (mediumId, pricelist) => {
  return {
    type: actionTypes.DOWNLOAD_FREIGABE_FILE,
    mediumId: mediumId,
    pricelist: pricelist
  };
};

export const sendChangeRequest = (
  mediumId,
  mediumName,
  userId,
  userName,
  email,
  companyId,
  message,
  file
) => {
  return {
    type: actionTypes.SEND_CHANGE_REQUEST,
    mediumId: mediumId,
    mediumName: mediumName,
    userId: userId,
    userName: userName,
    email: email,
    companyId: companyId,
    message: message,
    file: file
  };
};

export const downloadFreigabeFromList = (mediumId, appLanguage) => {
  return {
    type: actionTypes.DOWNLOAD_FREIGABE_FROM_LIST,
    mediumId: mediumId,
    appLanguage: appLanguage
  };
};

export const leaveFreigabeDetail = () => {
  return {
    type: actionTypes.LEAVE_FREIGABE_DETAIL
  };
};

const mediaImageURL = mediaId => {
  return apiEndpoints.MEDIA_IMAGE_ENDPOINT.replace("{id}", mediaId);
};
