import React, { Component } from "react";
import classes from "./ReleaseBanner.module.css";
import { Button } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";

class ReleaseBanner extends Component {
  state = {
    isVisible: true
  };

  hideStickyBanner = () => {
    this.setState({ isVisible: false });
    this.props.onHideReleaseBanner();
  };

  showReleaseOverlay = () => {
    this.props.onShowReleaseOverlay();
  };

  render() {
    const { appVersion, appLanguage } = this.props;
    const { isVisible } = this.state;

    let message = "";
    if (appLanguage === "en") {
      message =
        "Version " + appVersion + " is online. Discover the new features!";
    } else {
      message =
        "Version " +
        appVersion +
        " ist online. Entdecken Sie die neuen Funktionen!";
    }

    return isVisible ? (
      <>
        <div id="hintBanner" className={classes.Main}>
          <div style={{ width: "35%" }}></div>
          <div style={{ width: "65%", alignSelf: "center" }}>
            &nbsp;&nbsp;{message} &nbsp;&nbsp;&nbsp;&nbsp;
            <Button
              className={classes.DiscoverButton}
              outline
              color="secondary"
              onClick={this.showReleaseOverlay}
            >
              <Translate id="Home.ReleaseBanner.Button" />
            </Button>{" "}
          </div>
          <div className={classes.CloseDiv} style={{ width: "30px" }}>
            <div
              className={classes.CloseButton}
              onClick={this.hideStickyBanner}
            >
              x
            </div>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default ReleaseBanner;
