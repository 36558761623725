import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import { addOneMonthToDate } from "../../helpers/utility";
import CertificationAlert from "../../components/UI/CertificationAlert/CertificationAlert";
import CertificationCancelModal from "../../components/CertificationQualified/CertificationCancelModal/CertificationCancelModal";
import CertificationRequestModal from "../../components/CertificationQualified/CertificationRequestModal/CertificationRequestModal";

class CertificationQualified extends Component {
  state = {
    showCertificationCancelModal: false,
    showCertificationRequestModal: false
  };

  render() {
    const {
      showCertificationCancelModal,
      showCertificationRequestModal
    } = this.state;

    const { appLanguage } = this.props;
    return (
      this.shouldShowCertificationAlertHander() && (
        <>
          <CertificationAlert
            appLanguage={appLanguage}
            onShowCertificationCancelModal={
              this.showCertificationCancelModalHandler
            }
            onShowCertificationRequest={this.showCertificationRequestHandler}
          />
          <CertificationCancelModal
            showCancelModal={showCertificationCancelModal}
            handleCancelConfirm={this.discontinueCertificationProcessHandler}
            handleCancelAbort={this.continueCertificationProcessHandler}
          />

          <CertificationRequestModal
            showModal={showCertificationRequestModal}
            onHandleSending={this.sendCertificationRequestHandler}
            onHandleCancel={this.cancelCertificationRequestHandler}
            onHandleFinishRequest={this.discontinueCertificationProcessHandler}
          />
        </>
      )
    );
  }

  shouldShowCertificationAlertHander = () => {
    const { certificationQualified } = this.props;

    let today = new Date();
    let certificationStartDate = new Date(
      certificationQualified.anzeigenAbZeitpunkt
    );

    
    return certificationStartDate < today;
  };

  showCertificationCancelModalHandler = () => {
    this.setState({ showCertificationCancelModal: true });
  };

  showCertificationRequestHandler = () => {
    this.setState({ showCertificationRequestModal: true });
  };

  continueCertificationProcessHandler = () => {
    this.setState({ showCertificationCancelModal: false });
  };

  discontinueCertificationProcessHandler = () => {
    const {
      certificationQualified,
      onPostponeCertificationOneMonth
    } = this.props;

    let certificationStartDate = new Date(
      certificationQualified.anzeigenAbZeitpunkt
    );

    let newCertificationStartDate = addOneMonthToDate(certificationStartDate);

    let newDatenAlsJson = {
      anzeigenAbZeitpunkt: newCertificationStartDate,
      istBereitsZertifiziert: certificationQualified.istBereitsZertifiziert
    };

    onPostponeCertificationOneMonth(newDatenAlsJson);
    this.setState({
      showCertificationCancelModal: false
    });
  };

  cancelCertificationRequestHandler = () => {
    this.setState({ showCertificationRequestModal: false });
  };

  sendCertificationRequestHandler = (event, values) => {
    this.setState({ showCertificationRequestModal: false });
    this.props.onSendCertificationRequest(values);
  };
}

const mapStateToProps = state => {
  return {
    appLanguage: state.auth.appLanguage,
    certificationQualified: state.auth.certificationQualified
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onPostponeCertificationOneMonth: newDatenAlsJson =>
      dispatch(actions.postponeCertificationOneMonth(newDatenAlsJson)),
    onSendCertificationRequest: billingAddress =>
      dispatch(actions.sendCertificationRequest(billingAddress))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationQualified);
