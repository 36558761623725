import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../helpers/utility";

const initialState = {
  mediaItems: null,
  mediaItemNames: null,
  mediaItem: null,
  mediaImage: null,
  selectedMediaItem: null,
  dates: [],
  selectedDate: null,
  error: false,
  paginationHeaders: null,
  errorMessage: "",
  isCertifiedSuccess: false
};

const leaveFreigabeDetail = (state, action) => {
  return updateObject(state, {
    ...initialState
  });
};

const setMediaItems = (state, action) => {
  return updateObject(state, {
    mediaItems: action.mediaItems,
    paginationHeaders: action.paginationHeaders,
    error: false,
    errorMessage: ""
  });
};

const fetchMediaItemsFailed = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: action.errorMessage
  });
};

const setFreigabeDates = (state, action) => {
  return updateObject(state, {
    selectedMediaItem: action.mediumId,
    dates: action.dates,
    selectedDate: action.dates[0]
  });
};

const initFreigabeDetailFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Freigabe media details can't be loaded"
  });
};

const setFreigabeMediaDetail = (state, action) => {
  return updateObject(state, {
    mediaItem: action.mediaItem,
    mediaImage: action.mediaImage,
    isCertifiedSuccess: false
  });
};

const fetchFreigabeInfoFail = (state, action) => {
  return updateObject(state, {
    error: true,
    errorMessage: "Freigable Info can't be loaded"
  });
};

const setFreigabeInfo = (state, action) => {
  let selectedDate = state.dates
    .filter(item => {
      return item.giltAb === action.datum;
    })
    .reduce((arr, el) => {
      return el;
    }, null);

  return updateObject(state, {
    contactPersons: action.contactPersons,
    issueWidth: action.issueWidth,
    issueHeight: action.issueHeight,
    issueMeasurement: action.issueMeasurement,
    satzspiegelWidth: action.satzspiegelWidth,
    satzspiegelHeight: action.satzspiegelHeight,
    beschnittZugaben: action.beschnittZugaben,
    issues: action.issues,
    formats: action.formats,
    placements: action.placements,
    selectedDate: selectedDate,
    pdfTypes: action.pdfTypes
  });
};

const setCertifiedMediumResult = (state, action) => {
  return updateObject(state, {
    isCertifiedSuccess: action.status === 200 ? true : false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_FREIGABE_MEDIA_ITEMS:
      return setMediaItems(state, action);
    case actionTypes.FETCH_FREIGABE_MEDIA_ITEMS_FAILED:
      return fetchMediaItemsFailed(state, action);
    case actionTypes.SET_FREIGABE_DATES:
      return setFreigabeDates(state, action);
    case actionTypes.INIT_FREIGABE_DETAIL_FAIL:
      return initFreigabeDetailFail(state, action);
    case actionTypes.SET_FREIGABE_MEDIA_DETAIL:
      return setFreigabeMediaDetail(state, action);
    case actionTypes.FETCH_FREIGABE_INFO_FAIL:
      return fetchFreigabeInfoFail(state, action);
    case actionTypes.SET_FREIGABE_INFO:
      return setFreigabeInfo(state, action);
    case actionTypes.SET_CERTIFIED_MEDIUM_RESULT:
      return setCertifiedMediumResult(state, action);
    case actionTypes.LEAVE_FREIGABE_DETAIL:
      return leaveFreigabeDetail(state, action);
    default:
      return state;
  }
};

export default reducer;
