import React, { Component } from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";
import { Button } from "reactstrap";
import Translate from "../../../hoc/Locale/Translate";
import classes from "./CertificationAlert.module.css";

class CertificationAlert extends Component {
  state = {
    isVisible: true
  };

  hideStickyAlert = () => {
    this.props.onShowCertificationCancelModal();
  };

  render() {
    const { onShowCertificationRequest, appLanguage } = this.props;
    const { isVisible } = this.state;

    if (!isVisible) return null;

    return (
      <div id="hintBanner" className={classes.Main}>
        <div>
          <FontAwesome name="graduation-cap" size="3x" />
        </div>
        <div>
          <Button
            color="primary"
            outline
            className={classes.RequestButton}
            onClick={onShowCertificationRequest}
          >
            <Translate id="Certification.Apply" />
          </Button>
        </div>
        <div className={classes.Text}>
          {appLanguage === "en" ? (
            <div>
              <b>Congratulations!</b> You have met all the criteria for the{" "}
              <b>DUON CERTIFIED PROGRAM</b>. Your low error rate in the
              preflight proves that you are able to create print productions
              that meet the highest technical standards. You can now apply for
              your certification.{" "}
              <a
                className={classes.Link}
                href="https://duon-portal.blog/?p=1298"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about the CERTIFIED PROGRAM ...
              </a>
            </div>
          ) : (
            <div>
              <b>Herzlichen Glückwunsch!</b> Sie haben alle Kriterien für das{" "}
              <b>DUON CERTIFIED PROGRAM</b> erfüllt. Ihre geringe Fehlerquote im
              Preflight beweist, dass Sie in der Lage sind, Printproduktionen zu
              erstellen, die technisch höchsten Ansprüchen genügen. Sie können
              nun Ihre Zertifizierung beantragen.{" "}
              <a
                className={classes.Link}
                href="https://duon-portal.blog/?p=1300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Erfahren Sie mehr über das CERTIFIED PROGRAM...
              </a>
            </div>
          )}
        </div>
        <div className={classes.CloseButton} onClick={this.hideStickyAlert}>
          <div>x</div>
        </div>
      </div>
    );
  }
}

CertificationAlert.propTypes = {
  appLanguage: PropTypes.string,
  onShowCertificationCancelModal: PropTypes.func,
  onShowCertificationRequest: PropTypes.func
};

export default CertificationAlert;
