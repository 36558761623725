import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import weStandWithUkraineImage from "../../../assets/images/we-stand-with-ukraine.png";
import classes from "./Intro.module.css";

const Intro = () => {
  return (
    <div>
      <div>
        <img
          width="230px"
          height="100px"
          src={weStandWithUkraineImage}
          alt="We stand with Ukraine>"
        />
        <br />
        <br />
      </div>
      <h1 className={classes.Header}>
        <Translate id="Home.FirstParagraph.Header" />
      </h1>
      <h4 className={classes.Header}>
        <Translate id="Home.FirstParagraph.SubHeader" />
      </h4>
      <div className={classes.Text}>
        <Translate id="Home.FirstParagraph.FirstLine" />
      </div>
      <div className={classes.Text}>
        <Translate id="Home.FirstParagraph.SecondLine" />
      </div>
      <div className={classes.Text}>
        <Translate id="Home.FirstParagraph.ThirdLine" />
      </div>
      <div></div>
    </div>
  );
};

export default Intro;
