import React from "react";
import PropTypes from "prop-types";
import EmailField from "../../Register/EmailField/EmailField";
import Translate from "../../../hoc/Locale/Translate";
import { translateAsLiteral } from "../../../helpers/utility";
import {
  Form,
  Button,
  Alert
} from "reactstrap";

const forgotPasswordForm = (props) => {
  const { success, error, isOpen, toggle, appLanguage, handleValidSubmit } =
    props;

  let colour = "warning",
    successMessage,
    errorMessage;
  if (success) {
    colour = "success";
    successMessage = (
      <p className="bold">
        <Translate id="ForgotPassword.Success" />
      </p>
    );
  } else if (error) {
    colour = "danger";
    errorMessage = (
      <p>
        <Translate id="ForgotPassword.Error" />
      </p>
    );
  }

  return (
    <Alert
      className="mx-auto w-50"
      color={colour}
      isOpen={isOpen}
      toggle={toggle}
    >
      <h4 className="alert-heading">
        <Translate id="ForgotPassword" />
      </h4>
      {successMessage}
      {errorMessage}
      <Form onSubmit={handleValidSubmit}>
          <EmailField
            label={translateAsLiteral(appLanguage, "ForgotPassword.YourEmail")}
            appLanguage={appLanguage}
            validate={props.validate}
            onUpdateValidate={props.onUpdateValidate}
            onChange={(e) =>props.onChange(e)}
            value={props.values.email}
          />
          <Button className="my-2" color="primary">
            <Translate id="ForgotPassword.Button.Send" />
          </Button>
      </Form>
    </Alert>
  );
};

forgotPasswordForm.propTypes = {
  appLanguage: PropTypes.string.isRequired,
  success: PropTypes.bool,
  error: PropTypes.bool,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  handleValidSubmit: PropTypes.func,
};

export default forgotPasswordForm;
