import React, { Component } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "./ReportDownloads.module.css";
import Translate from "../../../hoc/Locale/Translate";

class ReportDownloads extends Component {
  render() {
    const {
      statusId,
      appLanguage,
      id,
      showDownloads,
      onDownloadReportBlob,
      onOpenReportHTML,
      toggleShowDownloads
    } = this.props;

    let resultCell = null;

    if (parseInt(statusId, 10) >= 73) {
      resultCell = (
        <>
          <PopoverHeader>
            <Translate id="General.Download" />
          </PopoverHeader>
          <PopoverBody>
            <div
              className={classes.ActionContainer}
              onClick={e => onDownloadReportBlob(id, appLanguage)}
            >
              <div>
                <FontAwesome name="file-pdf-o" size="2x" />
              </div>
              <div className={classes.Action}>PDF Report</div>
            </div>
            <div
              className={classes.ActionContainer}
              onClick={e => onOpenReportHTML(id, appLanguage)}
            >
              <div>
                <FontAwesome name="html5" size="2x" />
              </div>
              <div className={classes.Action}>HTML Report</div>
            </div>
          </PopoverBody>
        </>
      );
    } else {
      resultCell = (
        <PopoverBody>
          <div>
            <div></div>
            <div className={classes.Action}>Kein Preflightergebnis</div>
          </div>
        </PopoverBody>
      );
    }

    return (
      <Popover
        placement="bottom"
        isOpen={showDownloads}
        target={"Results" + id}
        toggle={toggleShowDownloads}
      >
        {resultCell}
      </Popover>
    );
  }
}

ReportDownloads.propTypes = {
  statusId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  showDownloads: PropTypes.bool.isRequired,
  toggleShowDownloads: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    appLanguage: state.auth.appLanguage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onDownloadReportBlob: (jobId, appLanguage) =>
      dispatch(actions.downloadReportBlob(jobId, appLanguage)),
    onOpenReportHTML: (jobId, appLanguage) =>
      dispatch(actions.openReportHTML(jobId, appLanguage))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDownloads);
