import * as actionTypes from "./actionTypes";

export const fetchJobHistory = (containerId, jobId, appLanguage) => {
  return {
    type: actionTypes.FETCH_JOB_HISTORY,
    containerId: containerId,
    jobId: jobId,
    appLanguage: appLanguage
  };
};

export const setJobHistory = (containerId, jobId, operations) => {
  return {
    type: actionTypes.SET_JOB_HISTORY,
    containerId: containerId,
    jobId: jobId,
    operations: operations
  };
};

export const fetchJobHistoryFailed = errorMessage => {
  return {
    type: actionTypes.FETCH_JOB_HISTORY_FAILED,
    errorMessage: errorMessage
  };
};
