import "raf/polyfill";
import "@babel/polyfill";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import {thunk} from "redux-thunk";
import createSagaMiddleware from "redux-saga";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./index.css";

import App from "./App";
import { unregister } from "./registerServiceWorker";
import mediaReducer from "./store/reducers/media";
import jobBuilderReducer from "./store/reducers/jobBuilder";
import jobListReducer from "./store/reducers/jobList";
import authReducer from "./store/reducers/auth";
import mediaDetailReducer from "./store/reducers/mediaDetail";
import freigabeReducer from "./store/reducers/freigabe";
import jobHistoryReducer from "./store/reducers/jobHistory";
import publishersReducer from "./store/reducers/publisher";
import certifiedReducer from "./store/reducers/certifiedCompanies";
import {
  watchAuth,
  watchMedia,
  watchJobBuilder,
  watchJobList,
  watchMediaDetail,
  watchFreigabe,
  watchJobHistory,
  watchDuonCertified,
  watchPublishers,
} from "./store/sagas";

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const rootReducer = combineReducers({
  media: mediaReducer,
  jobBuilder: jobBuilderReducer,
  jobList: jobListReducer,
  auth: authReducer,
  mediaDetail: mediaDetailReducer,
  freigabe: freigabeReducer,
  jobHistory: jobHistoryReducer,
  certified: certifiedReducer,
  publishers: publishersReducer,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchMedia);
sagaMiddleware.run(watchJobBuilder);
sagaMiddleware.run(watchJobList);
sagaMiddleware.run(watchMediaDetail);
sagaMiddleware.run(watchFreigabe);
sagaMiddleware.run(watchJobHistory);
sagaMiddleware.run(watchDuonCertified);
sagaMiddleware.run(watchPublishers);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(app);
unregister();

export default store;
