import React from "react";
import PropTypes from "prop-types";
import logo from "../../../../assets/images/logo_duon.png";
import { NavbarBrand } from "reactstrap";
import classes from "./Logo.module.css";

const Logo = props => {
  const { setVisitedStyle } = props;
  return (
    <NavbarBrand
      href="/"
      className={classes.NavBarBrand}
      onClick={setVisitedStyle}
    >
      <img id="mswLogo" src={logo} height="40" alt="Duon-Portal Logo" />
      <div id="view"></div>
    </NavbarBrand>
  );
};

NavbarBrand.propTypes = {
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
};

Logo.propTypes = {
  setVisitedStyle: PropTypes.func
};

export default Logo;
