import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./SecondaryButton.module.css";

const secondaryButton = props => {
  const { disabled, onClick, text, width } = props;

  return (
    <Button
      disabled={disabled}
      className={classes.Button}
      onClick={onClick}
      style={width && { width: width }}
    >
      {text}
    </Button>
  );
};

secondaryButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  onClick: PropTypes.func
};

export default secondaryButton;
