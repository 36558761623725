import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import classes from "./PrimaryButton.module.css";

export const PrimaryButton = props => {
  const {
    outline,
    disabled,
    className,
    onClick,
    size,
    block,
    text,
    style
  } = props;
  const outlineClassName = outline === true ? classes.outline : "";

  return (
    <Button
      outline={outline}
      disabled={disabled}
      className={[
        classes.Button,
        outlineClassName,
        className ? className : ""
      ].join(" ")}
      onClick={onClick}
      size={size}
      block={block}
      style={style}
    >
      {text}
    </Button>
  );
};

export const FloatingButton = props => {
  const { outline, disabled, onClick, size, block, text } = props;
  const outlineClassName = outline === true ? classes.outline : "";
  return (
    <Button
      outline={outline}
      disabled={disabled}
      className={[classes.FloatingButton, outlineClassName].join(" ")}
      onClick={onClick}
      size={size}
      block={block}
    >
      {text}
    </Button>
  );
};

PrimaryButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  outline: PropTypes.bool,
  block: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  onClick: PropTypes.func
};

export default PrimaryButton;
