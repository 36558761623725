import PRIVATE_API from "../../privateApi";
import API from "../../api";
import { put, call, all, select, take } from "redux-saga/effects";
import * as actions from "../actions/index";
import { errorHandler } from "../../helpers/Logger/Logger";
import { ALL_FREIGABE_MEDIA_ENDPOINT } from "../../helpers/apiEndpoints";
import * as apiEndpoints from "../../helpers/apiEndpoints";
import { formatDateToGerman } from "../../helpers/utility";

const logError = (error, errorDetails, componentItem, endpoint) => {
  return errorHandler(
    error,
    "API Error",
    errorDetails,
    componentItem,
    endpoint
  );
};

export function* fetchFreigabeMediaItemsSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PRIVATE_API.get(mediaURL(action.searchKeyword));
    let paginationHeaders = null;
    if (response.headers["x-pagination"]) {
      paginationHeaders = JSON.parse(response.headers["x-pagination"]);
    }
    yield put(actions.setFreigabeMediaItems(response.data, paginationHeaders));
  } catch (error) {
    let errorMessage = "Media Items can't be loaded";
    if (error.response && error.response.status === 404) {
      errorMessage = "Keine entsprechenden Medien gefunden.";
    }
    yield put(actions.fetchFreigabeMediaItemsFailed(errorMessage));
    yield logError(
      error,
      error.response,
      "fetchMediaItems",
      mediaURL(action.searchKeyword)
    );
  }
}

export function* initFreigabeDetailSaga(action) {
  yield all([
    put(actions.fetchFreigabeDates(action.mediumId, action.appLanguage)),
    put(actions.fetchFreigabeMediaDetail(action.mediumId))
  ]);
}

export function* fetchFreigabeDatesSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PRIVATE_API.get(
      freigabeDatesURL(action.mediumId, action.appLanguage)
    );
    yield put(actions.setFreigabeDates(action.mediumId, response.data));

    if (response.data && response.data.length > 0) {
      let defaultDate = response.data[0].giltAb;
      yield put(
        actions.fetchFreigabeInfo(
          action.mediumId,
          defaultDate,
          action.appLanguage
        )
      );
      yield put({ type: "FETCH_FREIGABE_DATES_COMPLETE" });
    }
  } catch (error) {
    yield put(actions.initFreigabeDetailFail());
    yield logError(
      error,
      error.response,
      "initFreigabeDetail",
      freigabeDatesURL(action.mediumId)
    );
  }
}

export function* fetchFreigabeMediaDetailSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield API.get(mediaDetailURL(action.mediumId));
    yield put(actions.setFreigabeMediaDetail(action.mediumId, response.data));
    yield put({ type: "FETCH_FREIGABE_MEDIA_DETAIL_COMPLETE" });
  } catch (error) {
    yield put(actions.fetchMediaDetailFailed());
    yield logError(
      error,
      error.response,
      "fetchMediaDetail",
      mediaURL(action.mediumId)
    );
  }
}

export function* fetchFreigabeInfoSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PRIVATE_API.get(
      freigabeInfoURL(action.mediumId, action.datum, action.appLanguage)
    );
    yield put(actions.setFreigabeInfo(response.data, action.datum));
  } catch (error) {
    //yield put(actions.fetchFreigabeInfoFail());
    yield logError(
      error,
      error.response,
      "fetchFreigabeInfo",
      freigabeInfoURL(action.mediumId, action.datum, action.appLanguage)
    );
  }
}

export function* certifyMediumSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PRIVATE_API.post(
      certifyMediumURL(action.mediumId),
      {}
    );

    if (response.data) {
      yield put(actions.setCertifiedMediumResult(response.status));
    }
  } catch (error) {
    yield logError(
      error,
      error.response,
      "certifyMedium",
      certifyMediumURL(action.mediumId)
    );
  }
}

export function* downloadFreigabeFileSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      PRIVATE_API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    const response = yield PRIVATE_API.get(
      apiEndpoints.FREIGABE_FILE_DOWNLOAD_ENDPOINT.replace(
        "{id}",
        action.mediumId
      ).replace("{preisliste}", action.pricelist),
      { responseType: "blob" }
    );

    let disposition = response.headers["content-disposition"];
    let filename = "freigabe-" + action.mediumId + ".xlsx";

    if (disposition && disposition.indexOf("attachment") !== -1) {
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {}
}

export function* downloadFreigabeFromListSaga(action) {
  try {
    yield put(actions.initFreigabeDetail(action.mediumId, action.appLanguage));
    yield take("FETCH_FREIGABE_MEDIA_DETAIL_COMPLETE");
    yield take("FETCH_FREIGABE_DATES_COMPLETE");
    const getMediaItem = state => state.freigabe.mediaItem;
    const getSelectedDate = state => state.freigabe.selectedDate;

    const mediaItem = yield select(getMediaItem);
    const selectedDate = yield select(getSelectedDate);

    if (selectedDate && mediaItem && mediaItem.zertifizierungen) {
      let currentZertifizierung = mediaItem.zertifizierungen
        .filter(item => {
          return (
            formatDateToGerman(item.giltAb) ===
              formatDateToGerman(selectedDate.giltAb) &&
            formatDateToGerman(item.giltBis) ===
              formatDateToGerman(selectedDate.giltBis)
          );
        })
        .reduce((arr, el) => {
          return el;
        }, null);

      yield put(
        actions.downloadFreigabeFile(
          action.mediumId,
          currentZertifizierung.preisliste
        )
      );
    }
  } catch (error) {}
}

export function* sendChangeRequestSaga(action) {
  try {
    const token = yield call([localStorage, "getItem"], "token");
    if (token) {
      API.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    let payload = {
      MediumId: action.mediumId,
      MediumName1: action.mediumName,
      ErstellerId: action.userId,
      ErstellerName: action.userName,
      ErstellerEmail: action.email,
      FirmenId: action.companyId,
      Anforderungen: action.message,
      AttachmentToken: null,
      BearbeiterName: null,
      BearbeitungsZeitpunkt: null,
      ZertifiziererId: null,
      ZertifiziererName: null,
      ZertifizierungsZeitpunkt: null
    };

    if (action.file) {
      let file = yield convertFileToBase64(action.file);

      var attachmentPayload = {
        FileAsBase64: file,
        FileName: action.file.name
      };

      const attachmentResponse = yield PRIVATE_API.post(
        uploadAttachmentURL(action.mediumId),
        attachmentPayload
      );
      if (attachmentResponse.data) {
        payload = {
          MediumId: action.mediumId,
          MediumName1: action.mediumName,
          ErstellerId: action.userId,
          ErstellerName: action.userName,
          ErstellerEmail: action.email,
          FirmenId: action.companyId,
          Anforderungen: action.message,
          AttachmentToken: attachmentResponse.data.upload.token,
          BearbeiterName: null,
          BearbeitungsZeitpunkt: null,
          ZertifiziererId: null,
          ZertifiziererName: null,
          ZertifizierungsZeitpunkt: null
        };
      }
    }

    yield PRIVATE_API.post(changeRequestURL(action.mediumId), payload);
  } catch (error) {
    yield logError(
      error,
      error.response,
      "certifyMedium",
      changeRequestURL(action.mediumId)
    );
  }
}

const mediaURL = params => {
  let url =
    params.length > 0
      ? `${ALL_FREIGABE_MEDIA_ENDPOINT}?${params}`
      : `${ALL_FREIGABE_MEDIA_ENDPOINT}?Suchbegriff=*`;
  return url;
};

const mediaDetailURL = mediumId => {
  return apiEndpoints.MEDIA_ENDPOINT.replace("{id}", mediumId);
};

const freigabeDatesURL = (mediumId, appLanguage) => {
  return apiEndpoints.FREIGABE_DATES_ENDPOINT.replace("{id}", mediumId).replace(
    "{sprache}",
    appLanguage
  );
};

const freigabeInfoURL = (mediumId, datum, appLanguage) => {
  let url = apiEndpoints.FREIGABE_INFO_ENDPOINT.replace("{id}", mediumId);
  url = url.replace("{datum}", datum);
  return url.replace("{sprache}", appLanguage);
};

const certifyMediumURL = mediumId => {
  return apiEndpoints.FREIGABE_CERTIFY_MEDIUM_ENDPOINT.replace(
    "{id}",
    mediumId
  );
};

const changeRequestURL = mediumId => {
  return apiEndpoints.FREIGABE_CHANGE_REQUEST_ENDPOINT.replace(
    "{id}",
    mediumId
  );
};

const uploadAttachmentURL = mediumId => {
  return apiEndpoints.FREIGABE_ADD_ATTACHMENT_ENDPOINT.replace(
    "{id}",
    mediumId
  );
};

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(
        reader.result
          .replace("data:application/pdf;base64,", "")
          .replace("data:application/vnd.ms-excel;base64,", "")
          .replace(
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
            ""
          )
      );
    reader.onerror = error => reject(error);
  });

async function convertFileToBase64(file) {
  return await toBase64(file);
}
