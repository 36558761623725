import React from "react";
import Translate from "../../../hoc/Locale/Translate";
import FontAwesome from "react-fontawesome";
import classes from "./PublisherParticipation.module.css";
import { Button } from "reactstrap";

const PublisherParticipation = () => {
  return (
    <>
      <div id="teilnehmen">
        <h3 className={classes.Header}>
          <FontAwesome name="play-circle" />
          &nbsp;&nbsp;
          <Translate id="Home.Verlag.Participation.Header" />
        </h3>
        <div style={{ width: "38%", textAlign: "center", padding: "20px" }}>
          <Translate id="Home.Verlag.Participation.Text" />
        </div>
        <div>
          <a href="mailto:support@duon-portal.de?subject=Teilnahme am DUON-Portal">
            <Button
              className={classes.ParticipateButton}
              outline
              color="secondary"
            >
              <Translate id="Home.Verlag.Participation.Button" />
            </Button>{" "}
          </a>
        </div>
      </div>
    </>
  );
};

export default PublisherParticipation;
